<!-- eslint-disable max-len -->
<template>
  <svg width="57" height="12" viewBox="0 0 57 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5199 12H8.59992V2.39998H2.83992V12H0.919922V0.47998H10.5199V12Z"
      fill="currentColor"
    />
    <path
      d="M14.3555 0.47998H20.1155V2.39998H14.3555V0.47998ZM12.4355 2.39998H14.3555V10.08H12.4355V2.39998ZM20.1155 2.39998H22.0355V10.08H20.1155V2.39998ZM14.3555 10.08H20.1155V12H14.3555V10.08Z"
      fill="currentColor"
    />
    <path
      d="M23.9512 8.15998V0.47998H25.8712V8.15998H23.9512ZM33.5512 0.47998V12H31.6312V10.08H25.8712V8.15998H31.6312V0.47998H33.5512Z"
      fill="currentColor"
    />
    <path
      d="M35.4668 0.47998H45.0668V2.39998H41.2268V12H39.3068V2.39998H35.4668V0.47998Z"
      fill="currentColor"
    />
    <path
      d="M48.9024 0.47998H54.6624V2.39998H48.9024V0.47998ZM46.9824 2.39998H48.9024V8.15998H54.6624V2.39998H56.5824V12H54.6624V10.08H48.9024V12H46.9824V2.39998Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerLogoTitle',
};
</script>
