<template>
  <div class="bg-black">
    <table>
      <tbody>
        <tr>
          <td>
            code-letter
          </td>
          <td>
            <code-letter>Q</code-letter>
            <code-letter>W</code-letter>
          </td>
        </tr>
        <tr>
          <td>
            is-primary
          </td>
          <td>
            <Button is-primary>
              Новая игра
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-has-bg is-text-colored is-uppercase is-full-width
          </td>
          <td>
            <Button is-has-bg is-red is-text-colored is-uppercase is-full-width>
              Красная таблетка
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-has-bg is-text-colored is-uppercase is-full-width
          </td>
          <td>
            <Button is-has-bg is-text-colored is-uppercase is-full-width>
              Пробудиться
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-blue is-text-colored is-has-bg is-uppercase is-full-width
          </td>
          <td>
            <Button is-blue is-text-colored is-has-bg is-uppercase is-full-width>
              Остаться в системе
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-full-width
          </td>
          <td>
            <Button is-full-width>Хочу получить максимум информации</Button>
          </td>
        </tr>
        <tr>
          <td>
            is-red is-full-width
          </td>
          <td>
            <Button is-red is-full-width>Давай короче, у меня ещё дел по горло</Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-full-width
          </td>
          <td>
            <Button is-next is-full-width>Понятно</Button>
          </td>
        </tr>
        <tr>
          <td>
            is-prev is-text-colored is-red is-uppercase
          </td>
          <td>
            <Button is-prev is-text-colored is-red is-uppercase>Не верно</Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-text-colored is-uppercase
          </td>
          <td>
            <Button is-next is-text-colored is-uppercase>Верно</Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-has-bg is-blue is-full-width is-text-colored is-uppercase
          </td>
          <td>
            <Button is-next is-has-bg is-blue is-full-width is-text-colored is-uppercase>
              Далее
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-full-width is-uppercase
          </td>
          <td>
            <Button is-full-width is-uppercase>
              Далее
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-addon-colored is-full-width is-uppercase
          </td>
          <td>
            <Button is-next is-addon-colored is-full-width is-uppercase>
              Далее
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            disabled is-full-width is-uppercase
          </td>
          <td>
            <Button disabled is-full-width is-uppercase>
              Ввести код
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-prev is-red is-text-colored is-full-width is-uppercase
          </td>
          <td>
            <Button is-prev is-red is-text-colored is-full-width is-uppercase>
              Начать новую игру
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-gray is-full-width is-mono is-has-bg is-addon-colored
          </td>
          <td>
            <Button is-next is-gray is-full-width is-mono is-has-bg is-addon-colored>
              А что мне, я первоначально задачи распределил - дальше пусть сами
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            is-next is-gray is-full-width is-mono is-has-bg is-addon-colored
          </td>
          <td>
            <Button is-prev is-gray is-full-width is-mono is-has-bg is-addon-colored>
              А что мне, я первоначально задачи распределил - дальше пусть сами
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import CodeLetter from '../components/UI/CodeLetter.vue';

export default {
  components: {
    Button,
    CodeLetter,
  },
  name: 'UiPage',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
td {
  padding: 16px;
  color: white;
  text-align: left;
}
</style>
