<template>
  <component
    :is="tag"
    class="martian-button"
    :disabled="disabled"
    :href="href"
    :class="{
      'martian-button--icon': !!$slots.icon,
      'martian-button--red': isRed,
      'martian-button--blue': isBlue,
      'martian-button--next': isNext,
      'martian-button--prev': isPrev,
      'martian-button--disabled': disabled,
      'martian-button--colored-text': isTextColored,
      'martian-button--big-radius': isBigRadius,
      'martian-button--has-bg': isHasBg,
      'martian-button--full-width': isFullWidth,
      'martian-button--half-width': isHalfWidth,
      'martian-button--addon-colored': isAddonColored,
      'martian-button--selected': isSelected,
      'martian-button--primary': isPrimary,
      'martian-button--white': isWhite,
      'martian-button--gray': isGray,
      uppercase: isUppercase,
      'martian-button--mono': isMono,
    }"
    @click="$emit('click', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <span v-if="isPrev" class="martian-button__prepend">
      <!-- eslint-disable -->
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7.33333H6.27337L7.80671 5.80667C7.86887 5.74451 7.91817 5.67071 7.95181 5.5895C7.98545 5.50829 8.00277 5.42124 8.00277 5.33333C8.00277 5.24543 7.98545 5.15838 7.95181 5.07717C7.91817 4.99595 7.86887 4.92216 7.80671 4.86C7.74455 4.79784 7.67076 4.74853 7.58954 4.71489C7.50833 4.68125 7.42128 4.66394 7.33337 4.66394C7.24547 4.66394 7.15842 4.68125 7.07721 4.71489C6.99599 4.74853 6.9222 4.79784 6.86004 4.86L4.19337 7.52667C4.13268 7.59007 4.0851 7.66483 4.05337 7.74667C3.9867 7.90897 3.9867 8.09103 4.05337 8.25333C4.0851 8.33517 4.13268 8.40993 4.19337 8.47333L6.86004 11.14C6.92202 11.2025 6.99575 11.2521 7.07699 11.2859C7.15823 11.3198 7.24537 11.3372 7.33337 11.3372C7.42138 11.3372 7.50852 11.3198 7.58976 11.2859C7.671 11.2521 7.74473 11.2025 7.80671 11.14C7.86919 11.078 7.91879 11.0043 7.95263 10.9231C7.98648 10.8418 8.00391 10.7547 8.00391 10.6667C8.00391 10.5787 7.98648 10.4915 7.95263 10.4103C7.91879 10.329 7.86919 10.2553 7.80671 10.1933L6.27337 8.66667H14C14.1769 8.66667 14.3464 8.59643 14.4714 8.4714C14.5965 8.34638 14.6667 8.17681 14.6667 8C14.6667 7.82319 14.5965 7.65362 14.4714 7.5286C14.3464 7.40357 14.1769 7.33333 14 7.33333ZM2.00004 2C1.82323 2 1.65366 2.07024 1.52864 2.19526C1.40361 2.32029 1.33337 2.48986 1.33337 2.66667V13.3333C1.33337 13.5101 1.40361 13.6797 1.52864 13.8047C1.65366 13.9298 1.82323 14 2.00004 14C2.17685 14 2.34642 13.9298 2.47145 13.8047C2.59647 13.6797 2.66671 13.5101 2.66671 13.3333V2.66667C2.66671 2.48986 2.59647 2.32029 2.47145 2.19526C2.34642 2.07024 2.17685 2 2.00004 2Z"
          fill="currentColor"
        />
      </svg>
      <!-- eslint-enable -->
    </span>
    <span class="martian-button__text">
      <span v-if="!!$slots.icon" class="martian-button__icon">
        <slot name="icon" />
      </span>
      <slot />
    </span>
    <span v-if="isNext" class="martian-button__append">
      <!-- eslint-disable -->
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.50002 8.66667L10.2267 8.66667L8.69335 10.1933C8.63119 10.2555 8.58189 10.3293 8.54825 10.4105C8.51461 10.4917 8.49729 10.5788 8.49729 10.6667C8.49729 10.7546 8.51461 10.8416 8.54825 10.9228C8.58189 11.004 8.63119 11.0778 8.69335 11.14C8.75551 11.2022 8.82931 11.2515 8.91052 11.2851C8.99174 11.3187 9.07878 11.3361 9.16669 11.3361C9.25459 11.3361 9.34164 11.3187 9.42285 11.2851C9.50407 11.2515 9.57786 11.2022 9.64002 11.14L12.3067 8.47333C12.3674 8.40993 12.415 8.33517 12.4467 8.25333C12.5134 8.09103 12.5134 7.90897 12.4467 7.74667C12.415 7.66483 12.3674 7.59007 12.3067 7.52667L9.64002 4.86C9.57805 4.79751 9.50431 4.74792 9.42307 4.71407C9.34183 4.68022 9.2547 4.6628 9.16669 4.6628C9.07868 4.6628 8.99154 4.68022 8.9103 4.71407C8.82906 4.74792 8.75533 4.79751 8.69335 4.86C8.63087 4.92198 8.58127 4.99571 8.54743 5.07695C8.51358 5.15819 8.49616 5.24532 8.49616 5.33333C8.49616 5.42134 8.51358 5.50848 8.54743 5.58972C8.58127 5.67096 8.63087 5.74469 8.69335 5.80667L10.2267 7.33333L2.50002 7.33333C2.32321 7.33333 2.15364 7.40357 2.02862 7.52859C1.90359 7.65362 1.83335 7.82319 1.83335 8C1.83335 8.17681 1.90359 8.34638 2.02862 8.4714C2.15364 8.59643 2.32321 8.66667 2.50002 8.66667ZM14.5 14C14.6768 14 14.8464 13.9298 14.9714 13.8047C15.0964 13.6797 15.1667 13.5101 15.1667 13.3333L15.1667 2.66667C15.1667 2.48986 15.0965 2.32029 14.9714 2.19526C14.8464 2.07024 14.6768 2 14.5 2C14.3232 2 14.1536 2.07024 14.0286 2.19526C13.9036 2.32029 13.8334 2.48986 13.8334 2.66667L13.8334 13.3333C13.8334 13.5101 13.9036 13.6797 14.0286 13.8047C14.1536 13.9298 14.3232 14 14.5 14Z"
          fill="currentColor"
        />
      </svg>
      <!-- eslint-enable -->
    </span>
  </component>
</template>

<script>
// eslint-disable-next-line max-len
export default {
  props: {
    isRed: Boolean,
    isBlue: Boolean,
    isTextColored: Boolean,
    isUppercase: Boolean,
    isBigRadius: Boolean,
    isHasBg: Boolean,
    isFullWidth: Boolean,
    isHalfWidth: Boolean,
    isNext: Boolean,
    isPrev: Boolean,
    isPrimary: Boolean,
    isAddonColored: Boolean,
    isSelected: Boolean,
    isWhite: Boolean,
    isGray: Boolean,
    isMono: Boolean,
    disabled: Boolean,
    tag: { type: String, default: 'button' },
    href: String,
  },
};
</script>

<style lang="scss" scoped>
.martian-button {
  @apply bg-black relative border text-12 font-navigo-medium font-bold px-32 rounded-4;
  @apply text-white border-green;
  --height: 40px;
  height: var(--height);
  line-height: var(--height);
  appearance: button;
  -moz-appearance: button;
  -webkit-appearance: button;
  &:hover:not(:disabled) {
    background: rgba(0, 164, 96, 0.3);
    @apply text-white;
  }

  &:active:not(:disabled) {
    @apply bg-green;
  }

  &:focus {
    @apply outline-none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--primary {
    @apply text-14 leading-22 rounded-4 bg-primary border-none font-navigo-medium
      uppercase text-martian-black;

    &:hover:not(:disabled) {
      @apply bg-yellow-hover;
      @apply text-martian-black;
    }

    &:active:not(:disabled) {
      @apply bg-yellow-pressed;
    }
  }

  &--white {
    @apply text-12 leading-16 text-martian-gray border-martian-gray font-navigo-regular;
    @apply text-opacity-50 border-opacity-50;
    background: none;

    &:hover:not(:disabled) {
      @apply text-opacity-90 border-opacity-90;
      background: none;
    }

    &:active:not(:disabled) {
      @apply bg-martian-gray text-martian-black text-opacity-80 bg-opacity-80;
    }
  }

  &--colored-text {
    @apply text-green;

    &.martian-button--blue {
      @apply text-blue;
    }

    &.martian-button--red {
      @apply text-red;
    }
  }

  &--addon-colored {
    .martian-button__append {
      @apply text-green;
    }

    &.martian-button--red {
      .martian-button__prepend {
        @apply text-red;
      }
    }

    &.martian-button--gray {
      .martian-button__prepend {
        color: #646879;
      }
      .martian-button__append {
        color: #646879;
      }
    }
  }

  &--blue {
    @apply border-blue;
    &:hover:not(:disabled) {
      background: linear-gradient(0deg, rgba(71, 93, 235, 0.3), rgba(71, 93, 235, 0.3)), #101010;
      @apply text-white;
    }

    &:active:not(:disabled) {
      @apply bg-blue;
    }
  }

  &--gray {
    @apply text-left;
    border: 1px solid #646879;
    padding-left: 8px;
    &.martian-button--prev {
      padding-right: 14px;

      .martian-button__text {
        text-align: left;
        margin-left: 28px;
      }
    }
    .martian-button__text {
      text-align: left;
    }
    &:hover:not(:disabled) {
      background: linear-gradient(
          90.54deg,
          rgba(100, 104, 121, 0) 52.87%,
          rgba(100, 104, 121, 0.5) 98.57%
        ),
        #000000;
      @apply text-white;
    }

    &:active:not(:disabled) {
      background: #646879;
    }
  }

  &--red {
    @apply border-red;
    &:hover:not(:disabled) {
      background: rgba(255, 100, 90, 0.3);
      @apply text-white;
    }

    &:active:not(:disabled) {
      @apply bg-red;
    }
  }

  &--big-radius {
    @apply rounded-big;
  }

  &--full-width {
    @apply w-full;
  }

  &--half-width {
    width: 49%;
    & .martian-button__text {
      height: 38px;
      line-height: 38px;
    }
  }

  &--selected {
    @apply text-martian-black bg-martian-gray border-martian-gray;
  }

  &--has-bg {
    &:not([disabled]) {
      background: linear-gradient(
          90.54deg,
          rgba(0, 164, 96, 0) 62.72%,
          rgba(0, 164, 96, 0.3) 106.41%
        ),
        #000000;

      &.martian-button--blue {
        background: rgba(71, 93, 235, 0.2);
        &:hover:not(:disabled) {
          background: linear-gradient(0deg, rgba(71, 93, 235, 0.3), rgba(71, 93, 235, 0.3)),
          #101010;
          @apply text-white;
        }

        &:active:not(:disabled) {
          @apply bg-blue;
        }
      }

      &.martian-button--red {
        background: linear-gradient(
            270.54deg,
            rgba(255, 100, 90, 0) 77.26%,
            rgba(255, 100, 90, 0.3) 111.27%
          ),
          #000000;
        &:hover:not(:disabled) {
          background: linear-gradient(270.54deg, rgba(255, 100, 90, 0) 73.23%, #ff645a 99.58%),
            #000000;
          @apply text-white;
        }

        &:active:not(:disabled) {
          @apply bg-red;
        }
      }

      &.martian-button--gray {
        border-color: transparent;
        background: #2b2d33;
        &:hover:not(:disabled) {
          border-color: #646879;
          background: linear-gradient(
              90.54deg,
              rgba(100, 104, 121, 0) 62.72%,
              rgba(100, 104, 121, 0.3) 106.41%
            ),
            #000000;
          & .martian-button__prepend {
            color: #ffffff;
          }
          & .martian-button__append {
            color: #ffffff;
          }
        }

        &:active:not(:disabled) {
          background: #646879;
          .martian-button__prepend {
            @apply text-white;
          }
          .martian-button__append {
            @apply text-white;
          }
        }
      }

      &:hover:not(:disabled) {
        background: linear-gradient(90.59deg, rgba(0, 164, 96, 0) 61.72%, #00a460 99.49%), #000000;
        @apply text-white;
      }

      &:active:not(:disabled) {
        @apply bg-green;
      }
    }
  }

  &--next {
    @apply flex justify-around font-navigo-medium items-center;
    @apply text-12 leading-16;
    .martian-button__text {
      @apply flex-1 mr-4 whitespace-nowrap;
    }
    &.martian-button--gray {
      .martian-button__text {
        @apply flex-1 mr-4 whitespace-normal;
      }
    }
  }

  &--prev {
    @apply flex justify-around font-navigo-medium items-center;
    @apply text-12 leading-16;
    .martian-button__text {
      @apply flex-1 ml-4 whitespace-nowrap;
    }
    &.martian-button--gray {
      .martian-button__text {
        @apply flex-1 mr-4 whitespace-normal;
      }
    }
  }

  &--icon {
    @apply flex items-center;
    .martian-button__text {
      @apply flex items-center justify-center;
    }
  }

  &__append {
    @apply w-16 h-16 absolute;
    right: 18px;
    top: calc(50% - 8px);
  }

  &__prepend {
    @apply w-16 h-16 absolute;
    left: 18px;
    top: calc(50% - 8px);
  }

  &__icon {
    @apply mr-16;
  }

  &--disabled {
    background: #3f3f3f;
  }

  &--mono {
    @apply font-navigo-regular;
    letter-spacing: 0.3px;
  }
}
</style>
