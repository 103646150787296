<!-- eslint-disable max-len -->
<template>
  <svg width="102" height="13" viewBox="0 0 102 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5033 2.96158L10.9433 2.97221L101.508 10.8407L15.2419 5.88401L15.2631 3.00409L16.7031 3.01471L16.6394 11.6545L15.1994 11.6439L15.2313 7.32397L10.9114 7.29209L10.8795 11.612L9.43955 11.6013L9.5033 2.96158Z"
      fill="currentColor"
    />
    <path
      d="M19.6997 3.03683L24.0196 3.0687L24.009 4.50866L19.6891 4.47679L19.6997 3.03683ZM18.2492 4.46616L19.6891 4.47679L19.6572 8.79667L23.9771 8.82855L24.009 4.50866L25.449 4.51929L25.3958 11.7191L23.9559 11.7085L23.9665 10.2685L19.6466 10.2366L19.636 11.6766L18.196 11.666L18.2492 4.46616Z"
      fill="currentColor"
    />
    <path
      d="M28.435 5.98137L26.995 5.97074L27.0056 4.53078L28.4456 4.5414L28.435 5.98137ZM34.1948 6.02387L32.7549 6.01324L32.7655 4.57328L34.2054 4.58391L34.1948 6.02387ZM32.7442 7.4532L31.3043 7.44258L31.3149 6.00262L32.7549 6.01324L32.7442 7.4532ZM31.2936 8.88254L29.8537 8.87191L29.8643 7.43195L31.3043 7.44258L31.2936 8.88254ZM29.8431 10.3119L28.4031 10.3012L28.4137 8.86129L29.8537 8.87191L29.8431 10.3119ZM28.3925 11.7412L26.9525 11.7306L26.9631 10.2906L28.4031 10.3012L28.3925 11.7412ZM34.1523 11.7837L32.7124 11.7731L32.723 10.3331L34.1629 10.3438L34.1523 11.7837Z"
      fill="currentColor"
    />
    <path
      d="M40.0395 10.3871L41.4795 10.3977L41.4688 11.8377L37.149 11.8058L37.1596 10.3659L38.5995 10.3765L38.6102 8.93653L37.1702 8.9259L37.1808 7.48594L38.6208 7.49657L38.6314 6.05661L37.1915 6.04598L37.2021 4.60602L38.642 4.61665L38.6527 3.17669L40.0926 3.18731L40.082 4.62727L41.522 4.6379L41.5113 6.07786L40.0714 6.06724L40.0607 7.5072L41.5007 7.51782L41.4901 8.95778L40.0501 8.94716L40.0395 10.3871ZM37.1915 6.04598L37.1808 7.48594L35.7409 7.47532L35.7515 6.03536L37.1915 6.04598ZM42.93 8.96841L42.9194 10.4084L41.4795 10.3977L41.4901 8.95778L42.93 8.96841Z"
      fill="currentColor"
    />
    <path
      d="M44.4867 8.9799L45.9267 8.99052L45.9373 7.55056L44.4973 7.53994L44.508 6.09998L45.9479 6.1106L45.9586 4.67064L47.3985 4.68127L47.3879 6.12123L48.8279 6.13185L48.8385 4.69189L50.2784 4.70252L50.2678 6.14248L51.7078 6.15311L51.6972 7.59307L50.2572 7.58244L50.2466 9.0224L51.6865 9.03303L51.6759 10.473L50.2359 10.4624L50.2253 11.9023L48.7854 11.8917L48.796 10.4517L47.356 10.4411L47.3454 11.8811L45.9054 11.8704L45.9161 10.4305L44.4761 10.4199L44.4867 8.9799ZM48.8066 9.01178L48.8172 7.57181L47.3773 7.56119L47.3666 9.00115L48.8066 9.01178Z"
      fill="currentColor"
    />
    <path
      d="M57.5525 10.5164L58.9924 10.527L58.9818 11.9669L54.6619 11.9351L54.6725 10.4951L56.1125 10.5057L56.1231 9.06577L54.6832 9.05514L54.6938 7.61518L56.1337 7.62581L56.1444 6.18585L54.7044 6.17522L54.715 4.73526L56.155 4.74588L56.1656 3.30592L57.6056 3.31655L57.595 4.75651L59.0349 4.76714L59.0243 6.2071L57.5843 6.19647L57.5737 7.63643L59.0137 7.64706L59.003 9.08702L57.5631 9.07639L57.5525 10.5164ZM54.7044 6.17522L54.6938 7.61518L53.2538 7.60455L0.0423679 5.77184L54.7044 6.17522ZM60.443 9.09765L60.4324 10.5376L58.9924 10.527L59.003 9.08702L60.443 9.09765Z"
      fill="currentColor"
    />
    <path
      d="M63.5035 0.467994L67.8234 0.499873L67.8127 1.95183L63.4928 1.91995L63.5035 0.467994ZM64.8796 9.13039L64.869 10.5703L63.429 10.5597L63.4184 11.9997L61.9784 11.9891L62.0422 3.34929L63.4821 3.35992L63.4396 9.11976L64.8796 9.13039ZM69.242 3.40242L69.1782 12.0422L67.7383 12.0316L67.7702 7.71168L66.3302 7.70105L66.3408 6.26109L67.7808 6.27172L67.802 3.39179L69.242 3.40242ZM64.8902 7.69042L66.3302 7.70105L66.3196 9.14101L64.8796 9.13039L64.8902 7.69042Z"
      fill="currentColor"
    />
    <path
      d="M75.0867 7.76567L72.2067 7.74442L72.1749 12.0643L70.7349 12.0537L70.7987 3.41391L72.2386 3.42453L72.2174 6.30446L75.0973 6.32571L75.0867 7.76567ZM77.9878 4.907L76.5479 4.89637L76.5585 3.45641L77.9985 3.46704L77.9878 4.907ZM76.5373 6.33633L75.0973 6.32571L75.1079 4.88575L76.5479 4.89637L76.5373 6.33633ZM75.076 9.20563L75.0867 7.76567L76.5266 7.77629L76.516 9.21626L75.076 9.20563ZM76.516 9.21626L77.956 9.22688L77.9347 12.1068L76.4948 12.0962L76.516 9.21626Z"
      fill="currentColor"
    />
    <path
      d="M82.3926 9.25962L82.3819 10.6996L80.942 10.689L80.9314 12.1289L79.4914 12.1183L79.5551 3.47853L80.9951 3.48915L80.9526 9.249L82.3926 9.25962ZM86.7549 3.53166L86.6912 12.1714L85.2512 12.1608L85.2831 7.84091L83.8431 7.83029L83.8538 6.39033L85.2937 6.40095L85.315 3.52103L86.7549 3.53166ZM82.4032 7.81966L83.8431 7.83029L83.8325 9.27025L82.3926 9.25962L82.4032 7.81966Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiOptionsBroken',
};
</script>
