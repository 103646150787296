<template>
  <div class="quiz-card__scene">
    <div
      class="card"
      :class="{ 'card--flipped': isFlipped, 'card--unflip': isUnflip }"
      :style="cardStyle"
    >
      <div class="card-face card-backing">
        <Dialog
          ref="answerDialog"
          class="quiz__answer-dialog card-dialog"
          :class-name="'bordered'"
          :class="{ conversation__dialog: isManager }"
        >
          <p
            v-show="number - 1 == answered"
            class="text-16 leading-24 font-navigo-thin text-center mx-auto"
          >
            {{ question.question }}
          </p>
        </Dialog>
      </div>
      <div class="card-face card-front">
        <Dialog
          class="quiz__result-dialog card-dialog"
          :class="`${result ? 'success' : 'failure'}`"
        >
          <div class="result">
            <template v-if="result">
              <icon-success />
              <h4 class="font-navigo-medium text-green text-12 leading-16 mt-8 mb-20 uppercase">
                Правильно
              </h4>
            </template>
            <template v-else>
              <icon-failure />
              <h4 class="font-navigo-medium text-red text-12 leading-16 mt-8 mb-20 uppercase">
                Неправильно
              </h4>
            </template>
            {{ explanation }}
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import IconSuccess from './Icons/IconSuccess.vue';
import IconFailure from './Icons/IconFailure.vue';

export default {
  props: {
    number: Number,
    answered: Number,
    result: Boolean,
    explanation: String,
    question: {
      type: Object,
      default: () => ({
        question: '',
      }),
    },
    isManager: Boolean,
  },
  data() {
    return {
      isFlipped: false,
      isUnflip: false,
    };
  },
  components: {
    Dialog,
    IconSuccess,
    IconFailure,
  },
  methods: {
    flip() {
      this.isFlipped = true;
    },
    unflip() {
      this.isUnflip = true;
      setTimeout(() => {
        this.isFlipped = false;
        this.isUnflip = false;
      }, 500);
    },
  },
  computed: {
    cardStyle() {
      return {
        top: '0px',
      };
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.result {
  @apply text-16 leading-26 font-navigo-thin text-center mx-auto;
  & svg {
    @apply mx-auto;
  }
}
.quiz-card {
  &__scene {
    width: 100%;
    perspective: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
}

.card-front {
  background-size: contain;
}
.card-backing {
  background-size: cover;
  transform: rotateY(180deg);
}
.card-face {
  background: rgba(20, 20, 20, 0.6);
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.card-backing {
    z-index: 5;
  }

  &.card-front {
    z-index: 4;
  }
}

.card-dialog {
  width: 100%;
  min-height: 288px;
}

.card {
  height: 100%;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  &.card--flipped {
    animation: cardFlip 0.35s forwards linear;
  }
  &.card--unflip {
    animation: cardUnFlip 0.35s forwards linear;
  }
}

.quiz__result-dialog {
  background: linear-gradient(0deg, rgba(100, 104, 121, 0.05), rgba(100, 104, 121, 0.05)), #101010;
  border: 1px solid #646879;
  &.success {
    background-color: rgba(88, 197, 133, 0.04);
    border: 1px solid rgba(88, 197, 133, 0.4);
  }
  &.failure {
    background: rgba(204, 31, 68, 0.04);
    border: 1px solid rgba(204, 31, 68, 0.4);
  }
}

@keyframes cardAppear {
  0% {
    transform: rotate3d(0, 0, 1, 15deg) translate3d(0, -100vh, 0);
  }
  30% {
    transform: rotate3d(0, 0, 0, 0deg) translate3d(0, 0, 0);
  }
  65% {
    transform: rotate3d(0, 0, 0, 0deg) translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg) scale3d(1.05, 1.05, 1);
  }
}

@keyframes cardFlip {
  0% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg);
  }
}

@keyframes cardUnFlip {
  0% {
    transform: rotateZ(0deg) rotateY(0deg);
  }
  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }
  100% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
}
</style>
