<template>
  <div class="outro">
    <matrix-rain :launchMatrix="startMatrix" />
    <EndOfGame class="outro__endofgame" ref="outroEndOfGame" @restart="restart" />
    <GameResult class="outro__result" ref="outroResult" @click="goToEndOfGame" />
    <conversation v-if="showConversation" :content="dialogContent" :endOfDialog="goNext" />
  </div>
</template>

<script>
/* eslint-disable */
import gsap from 'gsap';

import GameResult from '@/components/GameResult.vue';
import EndOfGame from '@/components/EndOfGame.vue';
import Conversation from './Conversation.vue';

import dialogContent from '@/assets/text/gameOutroText.json';

import { scaleCanvas } from '@/utils';
import MatrixRain from './MatrixRain.vue';

const DIALOG_TIME = 4000;
const OPACITY_DURATION = 1000;

const FADE_OUT_DURATION = 3000;
const RESULT_SCREEN_DELAY = 1000;
const RESULT_SCREEN_FADE_IN = 1000;

const END_OF_GAME_FADE_IN = 3000;

const TWEEN = require('tween.js');

export default {
  data() {
    return {
      dialogShown: true,
      matrixInterval: null,
      bgState: 'STATIC',
      dialogClicked: false,
      nextClicked: false,
      showConversation: true,
      dialogContent: dialogContent,
      startMatrix: false,
    };
  },
  props: {},
  components: {
    GameResult,
    EndOfGame,
    Conversation,
    MatrixRain,
  },
  computed: {},
  mounted() {},
  methods: {
    restart() {
      this.$store.dispatch('game/restartGame');
    },
    showDialog() {
      this.dialogShown = true;
    },
    goNext() {
      if (this.dialogClicked) {
        return;
      }
      this.showConversation = false;
      this.startMatrix = true;

      this.dialogClicked = true;
      const opacityTweenFrom = { opacity: 1 };
      const opacityTweenTo = { opacity: 0 };

      setTimeout(() => {
        this.bgState = 'PHASE_2';
        new TWEEN.Tween(opacityTweenTo)
          .to(
            {
              opacity: 1,
            },
            RESULT_SCREEN_FADE_IN,
          )
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            if (this.$refs.outroResult) {
              this.$refs.outroResult.$el.style.opacity = `${opacityTweenTo.opacity}`;
            }
          })
          .delay(RESULT_SCREEN_DELAY)
          .start();
      }, FADE_OUT_DURATION);
    },
    goToEndOfGame() {
      if (this.nextClicked) {
        return;
      }
      this.nextClicked = true;
      const opacityTweenFrom = { opacity: 1 };

      new TWEEN.Tween(opacityTweenFrom)
        .to(
          {
            opacity: 0,
          },
          END_OF_GAME_FADE_IN,
        )
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          if (this.$refs.outroResult) {
            this.$refs.outroResult.$el.style.opacity = `${opacityTweenFrom.opacity}`;
          }
        })
        .onComplete(() => {
          if (this.$refs.outroResult) {
            this.$refs.outroResult.$el.style.pointerEvents = 'none';
          }
          if (this.$refs.outroEndOfGame) {
            this.$refs.outroEndOfGame.show();
          }
        })
        .start();
    },
  },
};
</script>

<style lang="scss" scoped>
.martix-rain {
  @apply absolute;
  left: 0;
  top: 0;
}

.outro {
  @apply h-full;
  &__background {
    @apply absolute w-full h-full bg-no-repeat bg-center bg-cover opacity-0;
    @apply left-0 top-0;
    z-index: 0;
    background-size: cover;
    background-position: center center;
  }

  &__result {
    @apply absolute left-0 top-0 opacity-0 w-full;
  }

  &__endofgame {
    @apply absolute left-0 top-0 opacity-0 w-full;
    @apply pb-12;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
