<!-- eslint-disable max-len -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 4H2V20H22V4ZM4 18V6H20V18H4ZM8 8H6V10H8V12H10V14H14V12H16V10H18V8H16V10H14V12H10V10H8V8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerLogoMail',
};
</script>
