<!-- eslint-disable max-len -->
<template>
  <svg width="37" height="12" viewBox="0 0 37 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.18517 8.652V10.092H6.30517V11.532H4.86517V10.092H1.98517V8.652H4.86517V4.332H1.98517V2.892H9.18517V4.332H6.30517V8.652H9.18517ZM0.545166 8.652V4.332H1.98517V8.652H0.545166ZM10.6252 4.332V8.652H9.18517V4.332H10.6252Z"
      fill="currentColor"
    />
    <path
      d="M13.6247 2.892H17.9447V4.332H13.6247V2.892ZM12.1847 4.332H13.6247V8.652H17.9447V4.332H19.3847V11.532H17.9447V10.092H13.6247V11.532H12.1847V4.332Z"
      fill="currentColor"
    />
    <path
      d="M22.3814 0H26.7014V1.452H22.3814V0ZM23.8214 8.652V10.092H22.3814V11.532H20.9414V2.892H22.3814V8.652H23.8214ZM28.1414 2.892V11.532H26.7014V7.212H25.2614V5.772H26.7014V2.892H28.1414ZM23.8214 7.212H25.2614V8.652H23.8214V7.212Z"
      fill="currentColor"
    />
    <path
      d="M34.0181 2.892V4.332H32.5781V2.892H34.0181ZM31.1381 4.332H32.5781V5.772H31.1381V4.332ZM35.4581 4.332V5.772H34.0181V4.332H35.4581ZM31.1381 5.772V11.532H29.6981V5.772H31.1381ZM36.8981 5.772V11.532H35.4581V5.772H36.8981Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiFile',
};
</script>
