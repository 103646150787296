<!-- eslint-disable max-len -->
<template>
  <svg width="100" height="14" viewBox="0 0 100 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.919922 0.47998H8.59992V2.39998H2.83992V4.31998H8.59992V6.23998H2.83992V10.08H8.59992V12H0.919922V0.47998ZM8.59992 2.39998H10.5199V4.31998H8.59992V2.39998ZM8.59992 6.23998H10.5199V10.08H8.59992V6.23998Z" fill="white"/>
    <path d="M12.5955 0.47998H14.5155V2.39998H12.5955V0.47998ZM20.2755 0.47998H22.1955V2.39998H20.2755V0.47998ZM14.5155 2.39998H16.4355V4.31998H14.5155V2.39998ZM18.3555 2.39998H20.2755V4.31998H18.3555V2.39998ZM16.4355 4.31998H18.3555V6.23998H16.4355V4.31998ZM14.5155 6.23998H16.4355V8.15998H14.5155V6.23998ZM18.3555 6.23998H20.2755V8.15998H18.3555V6.23998ZM12.5955 8.15998H14.5155V12H12.5955V8.15998ZM20.2755 8.15998H22.1955V12H20.2755V8.15998Z" fill="white"/>
    <path d="M26.1912 0.47998H31.9512V2.39998H26.1912V0.47998ZM24.2712 2.39998H26.1912V10.08H24.2712V2.39998ZM31.9512 2.39998H33.8712V10.08H31.9512V2.39998ZM26.1912 10.08H31.9512V12H26.1912V10.08Z" fill="white"/>
    <path d="M47.4668 10.08V12H35.9468V10.08H37.8668V2.39998H39.7868V10.08H43.6268V2.39998H39.7868V0.47998H45.5468V10.08H47.4668Z" fill="white"/>
    <path d="M59.1443 12H57.2243V10.08H51.4643V8.15998H57.2243V2.39998H51.4643V0.47998H59.1443V12ZM49.5443 8.15998V2.39998H51.4643V8.15998H49.5443ZM49.5443 10.08H51.4643V12H49.5443V10.08Z" fill="white"/>
    <path d="M61.2199 10.08V0.47998H63.1399V10.08H61.2199ZM76.5799 10.08V13.92H74.6599V12H63.1399V10.08H66.9799V0.47998H68.8999V10.08H72.7399V0.47998H74.6599V10.08H76.5799Z" fill="white"/>
    <path d="M82.5012 8.15998V10.08H80.5812V12H78.6612V0.47998H80.5812V8.15998H82.5012ZM88.2612 0.47998V12H86.3412V6.23998H84.4212V4.31998H86.3412V0.47998H88.2612ZM82.5012 6.23998H84.4212V8.15998H82.5012V6.23998Z" fill="white"/>
    <path d="M90.3368 0.47998H99.9368V2.39998H92.2568V4.31998H98.0168V6.23998H92.2568V10.08H99.9368V12H90.3368V0.47998Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMailerIncoming',
};
</script>
