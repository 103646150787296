<!-- eslint-disable max-len -->
<template>
  <svg width="48" height="72" viewBox="0 0 48 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70894 72H8.14894V64.2H3.46894V72H1.90894V62.64H9.70894V72Z" fill="currentColor" />
    <path
      d="M12.9554 62.64H17.6354V64.2H12.9554V62.64ZM11.3954 64.2H12.9554V70.44H11.3954V64.2ZM17.6354 64.2H19.1954V70.44H17.6354V64.2ZM12.9554 70.44H17.6354V72H12.9554V70.44Z"
      fill="currentColor"
    />
    <path
      d="M20.8818 68.88V62.64H22.4418V68.88H20.8818ZM28.6818 62.64V72H27.1218V70.44H22.4418V68.88H27.1218V62.64H28.6818Z"
      fill="currentColor"
    />
    <path
      d="M30.3683 62.64H38.1683V64.2H35.0483V72H33.4883V64.2H30.3683V62.64Z"
      fill="currentColor"
    />
    <path
      d="M41.4147 62.64H46.0947V64.2H41.4147V62.64ZM39.8547 64.2H41.4147V68.88H46.0947V64.2H47.6547V72H46.0947V70.44H41.4147V72H39.8547V64.2Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44 8H4V40H44V8ZM8 36V12H40V36H8ZM16 16H12V20H16V24H20V28H28V24H32V20H36V16H32V20H28V24H20V20H16V16Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiMail',
};
</script>
