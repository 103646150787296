<template>
  <div class="quiz">
    <template v-if="showResult">
      <div class="quiz__dialogs">
        <Card ref="resultCard" no-border>
          <quiz-results
            :rightAnswers="rightAnswers"
            :wrongAnswers="wrongAnswers"
            :resultText="resultTextCurrent"
          />
        </Card>
      </div>
      <Button
        class="next-lvl-btn"
        ref="nextLevelBtn"
        is-primary
        is-uppercase
        :disabled="loading"
        @click="goToNextStage"
        >Далее</Button
      >
    </template>
    <template v-else-if="showOpenedCode">
      <div class="quiz__dialogs">
        <div class="quiz__letter-outer" ref="codeLetter">
          <div v-if="showLetter" class="quiz__letter-container">
            <img src="/images/blur-yellow.svg" class="quiz__letter-container-blur" />
            <div class="quiz__letter-border">
              <div class="quiz__letter">
                {{ codeLetter || '' }}
              </div>
            </div>
          </div>
        </div>
        <div class="quiz__code-info" :class="{ 'quiz__code-info--empty': !showLetter }">
          <div class="quiz__code-title">Ты открыл часть кода</div>
          <div class="quiz__code-letters">
            <div class="quiz__code-letters-container">
              <template v-for="(letter, i) in code">
                <CodeLetter v-if="letter" :key="i">{{ letter }}</CodeLetter>
                <CodeLetter v-else :key="i" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <Button is-uppercase is-primary @click="goFromCode">Далее</Button>
    </template>
    <template v-else>
      <div class="quiz__dialogs">
        <QuizCard
          v-for="(n, i) in maxQuestions"
          style="opacity: 0"
          :key="i"
          ref="quizCards"
          :number="totalQuestions - i"
          :result="result"
          :question="question"
          :explanation="explanation"
          :answered="answeredQuestions"
        />
      </div>
      <div class="quiz__buttons">
        <Button
          v-if="showNextButton"
          ref="nextButton"
          is-full-width
          is-primary
          is-uppercase
          @click="getNextQuestion"
          :disabled="loading"
          >Далее</Button
        >
        <div v-else class="quiz-answer-buttons flex w-full justify-between" ref="quizButtons">
          <Button
            class="quiz-answer-button--no"
            is-prev
            is-text-colored
            is-red
            is-uppercase
            isHalfWidth
            @click="answer(false)"
            :disabled="loading"
            >не верно</Button
          >
          <Button
            is-next
            is-text-colored
            is-uppercase
            isHalfWidth
            @click="answer(true)"
            :disabled="loading"
            >верно</Button
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import gsap, { Bounce } from 'gsap';
import Button from '@/components/UI/Button.vue';
import CodeLetter from '@/components/UI/CodeLetter.vue';
import Dialog from './Dialog.vue';
import IconSuccess from './Icons/IconSuccess.vue';
import IconFailure from './Icons/IconFailure.vue';

import QuizCard from './QuizCard.vue';

import Card from './Card.vue';
import QuizResults from './QuizResults.vue';

import resultText from '@/assets/text/quizResultText.json';

const TWEEN = require('tween.js');

const animate = (time) => {
  window.requestAnimationFrame(animate);
  TWEEN.update(time);
};

window.requestAnimationFrame(animate);

const SWIPE_ANIM_DURATION = 1500;
const SWIPE_ANIM_ANGLE_DELTA = 30;
const SWIPE_ANIM_X_DELTA = window.innerWidth > 576 ? 616 : window.innerWidth + 40;
const SWIPE_ANIM_Y_DELTA = 100;

export default {
  name: 'Quiz',
  props: {},
  components: {
    Button,
    Dialog,
    CodeLetter,
    IconSuccess,
    IconFailure,
    QuizCard,
    Card,
    QuizResults,
  },
  data() {
    return {
      result: null,
      negativeSelected: false,
      positiveSelected: false,
      showResult: false,
      loading: false,
      showOpenedCode: false,
      explanation: null,
      showNextButton: false,
      maxQuestions: 0,
      isInit: true,
      showLetter: false,
      resultText,
    };
  },
  computed: {
    hasResult() {
      return typeof this.result === 'boolean';
    },
    question() {
      return this.$store.state.game.question;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    currentStage() {
      return this.$store.getters['game/currentStage'];
    },
    currentStageNumber() {
      return this.$store.state.game.currentStageNumber;
    },
    totalQuestions() {
      return this.currentStage?.total_questions || 0;
    },
    answeredQuestions() {
      return this.currentStage?.answered_questions;
    },
    rightAnswers() {
      return this.currentStage?.correct_answers || 0;
    },
    wrongAnswers() {
      return this.totalQuestions - this.rightAnswers;
    },
    resultTextCurrent() {
      if (this.rightAnswers <= this.totalQuestions * 0.2) {
        return this.resultText.poor;
      }
      if (this.rightAnswers <= this.totalQuestions * 0.6) {
        return this.resultText.fair;
      }
      if (this.rightAnswers <= this.totalQuestions * 0.8) {
        return this.resultText.good;
      }
      return this.resultText.excellent;
    },
    code() {
      return this.$store.getters['game/code'];
    },
    codeLetter() {
      return this.currentStage.code_part;
    },
  },
  async mounted() {
    if (this.answeredQuestions < this.totalQuestions) {
      await this.$store.dispatch('game/fetchQuestion');
      this.maxQuestions = this.totalQuestions - this.answeredQuestions;
      this.$nextTick(() => {
        this.showDeck();
        gsap.fromTo(
          this.$refs.quizButtons,
          { autoAlpha: 0 },
          {
            duration: this.maxQuestions * 0.25,
            autoAlpha: 1,
          },
        );
        this.$store.dispatch(`game/activateCounter`);
      });
    } else {
      this.resultAppear();
    }
  },
  watch: {
    async currentStage() {
      await this.$store.dispatch(`game/fetchQuestion`);
    },
  },
  methods: {
    animateResultCard() {
      if (!this.$refs.nextLevelBtn) {
        return;
      }
      if (this.$refs.nextLevelBtn) {
        gsap.to(this.$refs.nextLevelBtn.$el, {
          duration: 1,
          autoAlpha: 0,
        });
      }

      this.$refs.resultCard.disappear(() => {
        this.showResult = false;
        this.$nextTick(() => {
          this.showDeck();
          gsap.fromTo(
            this.$refs.quizButtons,
            { autoAlpha: 0 },
            {
              duration: this.maxQuestions * 0.25,
              autoAlpha: 1,
            },
          );
        });
      });
    },
    async goToNextStage() {
      if (this.currentStageNumber < 4) {
        this.loading = true;
        this.$store.dispatch(`game/clearQuestion`);
        await this.$store
          .dispatch(`game/fetchQuestion`, this.currentStage + 1)
          .then(() => {
            this.maxQuestions = this.totalQuestions;
            this.animateResultCard();
            this.$store.dispatch(`game/upStage`);
            this.$store.dispatch(`game/activateCounter`);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit('game/SET_SHOW_FINAL', true);
      }
    },
    showDeck() {
      if (this.$refs.quizCards.length) {
        const c = this.$refs.quizCards[this.$refs.quizCards.length - 1];
        if (c && c.$el) {
          c.$el.style.opacity = 1;
          gsap.from(c.$el, {
            duration: 0.5,
            scale: 0,
            autoAlpha: 0,
          });
        }
      }
    },
    hideQuizButtons() {
      gsap.to(this.$refs.quizButtons, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => {
          this.showNextButton = true;
          this.$nextTick(() => {
            if (this.$refs.nextButton) {
              gsap.fromTo(
                this.$refs.nextButton.$el,
                { autoAlpha: 0 },
                {
                  duration: 0.5,
                  autoAlpha: 1,
                },
              );
            }
          });
        },
      });
    },
    hideNextButton(cb) {
      if (this.$refs.nextButton) {
        gsap.to(this.$refs.nextButton.$el, {
          duration: 0.6,
          autoAlpha: 0,
          onComplete: () => {
            cb();
          },
        });
      }
    },
    moveCard(res, cb) {
      const card = this.$refs.quizCards.pop();
      if (card) {
        gsap.to(card.$el, {
          duration: 1.2,
          x: res ? 1000 : -1000,
          y: 1000,
          rotation: res ? 180 : -180,
          onComplete: () => {
            cb && cb();
          },
        });
      }
    },
    getCardStyle(index) {
      return {
        opacity: 1,
      };
    },
    resultAppear() {
      this.showResult = true;
      this.$nextTick(() => {
        this.$refs.resultCard.appear(() => {
          setTimeout(() => {
            this.$refs.resultCard.flip();
          }, 250);
          if (this.$refs.nextLevelBtn) {
            gsap.fromTo(
              this.$refs.nextLevelBtn.$el,
              { autoAlpha: 0 },
              {
                duration: 0.5,
                autoAlpha: 1,
              },
            );
          }
        });
      });
    },
    goFromCode() {
      this.showOpenedCode = false;
      this.showLetter = false;
      this.resultAppear();
    },
    async answer(val) {
      this.$store.dispatch(`game/pauseCounter`);
      this.loading = true;
      await this.$http
        .post(`/game/answer-text-question/${this.question.id}`, {
          answer: val,
        })
        .then(async (resp) => {
          await this.$store.dispatch(`game/fetchGame`);
          if (resp.data) {
            this.result = resp.data.is_correct;
            this.explanation = resp.data.explanation;
            const card = this.$refs.quizCards[this.$refs.quizCards.length - 1];
            card.flip();
            this.hideQuizButtons();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getNextQuestion() {
      if (this.answeredQuestions < this.totalQuestions) {
        this.loading = true;
        await this.$store
          .dispatch(`game/fetchQuestion`)
          .then(() => {
            this.moveCard(this.result);
            this.hideNextButton(() => {
              this.result = null;
              this.showNextButton = false;
              this.showDeck();
              this.$store.dispatch(`game/activateCounter`);
              this.$nextTick(() => {
                gsap.fromTo(
                  this.$refs.quizButtons,
                  { autoAlpha: 0 },
                  {
                    autoAlpha: 1,
                    duration: 0.6,
                  },
                );
              });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$nextTick(() => {
          this.moveCard(this.result);
          this.hideNextButton(() => {
            this.result = null;
            this.showNextButton = false;
            this.$nextTick(() => {
              if (this.codeLetter) {
                this.showOpenedCode = true;
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.showLetter = true;
                    this.$nextTick(() => {
                      gsap.from(this.$refs.codeLetter, {
                        duration: 0.75,
                        scale: 2,
                        ease: Bounce.easeOut,
                      });
                    });
                  }, 500);
                });
              } else {
                this.resultAppear();
              }
            });
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.quiz {
  @apply relative z-0 flex flex-col justify-between pb-16;
  height: 100%;
  &__top-img {
    @apply absolute;
    width: 100%;
    top: -8px;
    left: 0;
  }
  &__buttons {
    @apply flex justify-between;
  }

  &__result-dialog {
    @apply w-full;
    z-index: -2;
  }

  &__answer-dialog {
    @apply absolute w-full top-4;
    min-height: 310px;
  }

  &__dialogs {
    @apply relative;
  }

  &__negative {
    @apply absolute bg-red h-320 w-20 z-10;
    left: -30px;
    opacity: 0;
    filter: blur(15px);
  }

  &__positive {
    @apply absolute bg-green h-320 w-20 z-10;
    right: -30px;
    opacity: 0;
    filter: blur(15px);
  }

  &__letter-outer {
    @apply flex items-center justify-center;
  }

  &__letter-container {
    @apply w-120 h-120 relative flex items-center justify-center;
    border-radius: 24px;
    padding: 16px;
    background: linear-gradient(
        90.55deg,
        rgba(255, 100, 90, 0) 0.43%,
        rgba(0, 164, 96, 0.05) 39.67%
      ),
      #101010;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      padding: 16px;
      background: linear-gradient(223.44deg, #fee600 1.62%, #fee600 101.07%);
    }
    &-blur {
      width: 320px;
      position: absolute;
      height: 320px;
      z-index: -1;
    }
  }

  &__letter-border {
    @apply w-full h-full text-center;
    position: relative;
    z-index: 9;
    background: linear-gradient(0deg, rgba(254, 230, 0, 0.15), rgba(254, 230, 0, 0.15)), #101010;
    border: 1.74px solid #fee600;
    padding-top: 10px;
  }

  &__letter {
    @apply uppercase font-navigo-black text-primary;
    font-size: 55px;
    line-height: 77px;
    -webkit-text-stroke: 2px #fee600;
    -webkit-text-fill-color: transparent;
  }

  &__code-info {
    @apply mt-48;
    &--empty {
      @apply mt-168;
    }
  }

  &__code-title {
    @apply text-16 leading-28 text-white font-navigo-medium uppercase text-center;
  }

  &__code-letters {
    @apply mt-8 flex justify-center;
  }

  &__code-letters-container {
    @apply grid grid-cols-4 gap-8;
  }
}

.card-1 {
  @apply top-4;
}

.card-2 {
  @apply top-8;
}

.card-3 {
  @apply top-12;
}

.card-4 {
  @apply top-16;
}

.card-5 {
  @apply top-20;
}

.quiz-answer-buttons {
  opacity: 0;
}

.next-lvl-btn {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.quiz-answer-button--no {
  .martian-button__text {
    margin-left: 16px;
  }
}
</style>
