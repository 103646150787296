import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import GamePage from '../pages/GamePage.vue';
import LandingPage from '../pages/LandingPage.vue';
import UiPage from '../pages/UI.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/ui',
    name: 'UiPage',
    component: UiPage,
  },
  {
    path: '/game',
    name: 'Game',
    component: GamePage,
    beforeEnter: async (to, from, next) => {
      if (store.getters.isAuthenticated) {
        await store.dispatch('game/fetchGame').then(() => {
          const gameStageCurrent = store.state.game.gameInfo?.stages.find(
            (s) => s.answered_questions < s.total_questions,
          );
          if (gameStageCurrent) {
            store.dispatch('game/setCurrentStageNumber', gameStageCurrent.stage);
          }
        });
        next();
        return;
      }
      next('/');
    },
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next('/game');
        return;
      }
      next('/');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
