<template>
  <div class="timer" :class="{ active: isCounterActive }">{{ stringTime }}</div>
</template>

<script>
export default {
  name: 'Counter',
  props: {},
  data() {
    return {
      currentTime: 0,
      timer: null,
    };
  },
  computed: {
    stringTime() {
      const numminutes = Math.floor((((this.currentTime % 31536000) % 86400) % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const numseconds = ((((this.currentTime % 31536000) % 86400) % 3600) % 60)
        .toString()
        .padStart(2, '0');
      return `${numminutes}:${numseconds}`;
    },
    isCounterActive() {
      return this.$store.state.game.isCounterActive;
    },
  },
  watch: {
    isCounterActive(val) {
      if (val) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },
  mounted() {
    const savedTime = localStorage.getItem('counter');
    if (savedTime) {
      this.currentTime = +savedTime;
    }
    if (this.isCounterActive) {
      this.startTimer();
    }
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.currentTime += 1;
          localStorage.setItem('counter', this.currentTime);
        }, 1000);
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss">
.timer {
  @apply font-navigo-regular text-12 leading-24 text-gray-300 text-center relative;
  &:last-child {
    &:after {
      @apply absolute leading-16 font-navigo-medium;
      bottom: calc(100% + 4px);
      width: max-content;
      content: 'ВРЕМЯ';
      right: 0;
      letter-spacing: 0.2px;
      color: #ffffff;
      font-size: 10px;
    }
  }
  background: transparent;
  border-radius: 2px;
  border: 1px solid #68676c;
  width: 67px;
  letter-spacing: 0.1px;
  color: #68676c;
  --height: 32px;
  height: var(--height);
  line-height: calc(var(--height) - 2px);
  &.active {
    color: #e2e2e2;
  }
}
</style>
