<template>
  <div class="window-ui" :class="{ 'window-ui--mobile': isMobile }">
    <div class="window-ui__container" ref="game">
      <div class="window-ui__header" v-if="!isMobile">
        <div class="window-ui__header-container">
          <div class="window-ui__logo">
            <glitch-item :iconName="'IconWindowUiLogo'" :isBroken="isBroken" />
          </div>
          <div class="window-ui__menu">
            <div class="window-ui__menu-item" v-for="iconName in menuIcons" :key="iconName">
              <glitch-item :iconName="iconName" :isBroken="isBroken" />
            </div>
            <div class="window-ui__menu-off">
              <glitch-item :iconName="'IconWindowUiOff'" :isBroken="isBroken" />
            </div>
          </div>
        </div>
      </div>
      <div class="window-ui__wrapper">
        <div class="window-ui__sidebar" v-if="!isMobile">
          <ul class="window-ui__sidebar-list">
            <li class="window-ui__sidebar-item" v-for="iconName in sidebarIcons" :key="iconName">
              <glitch-item :iconName="iconName" :isBroken="isBroken" />
            </li>
          </ul>
        </div>
        <div class="window-ui__body">
          <div class="mailer" ref="game">
            <div class="mailer__container">
              <matrix-rain :launchMatrix="startMatrix" />
              <div class="mailer__header">
                <div class="mailer-logo-mail">
                  <glitch-item :iconName="'IconMailerLogoMail'" :isBroken="isBroken" />
                </div>
                <div class="mailer-logo-mail-title">
                  <glitch-item :iconName="'IconMailerLogoTitle'" :isBroken="isBroken" />
                </div>
                <div class="mailer-logo-mail-close">
                  <glitch-item :iconName="'IconMailerClose'" :isBroken="isBroken" />
                </div>
              </div>
              <slot>
                <mailer-landing-content @start="glitch" />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap, { Power4 } from 'gsap';
import MatrixRain from '../MatrixRain.vue';
import MailerLandingContent from './MailerLandingContent.vue';
import GlitchItem from '../UI/GlitchItem.vue';

export default {
  name: 'WindowUi',
  components: {
    MatrixRain,
    MailerLandingContent,
    GlitchItem,
  },
  data() {
    return {
      startMatrix: false,
      sidebarIcons: [
        'IconWindowUiPc',
        'IconWindowUiTrash',
        'IconWindowUiMail',
        'IconWindowUiReport',
      ],
      menuIcons: [
        'IconWindowUiFile',
        'IconWindowUiWindows',
        'IconWindowUiOptions',
        'IconWindowUiRef',
      ],
    };
  },
  props: {
    isBroken: { type: Boolean, default: false },
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    this.$refs.game.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const newVh = window.innerHeight * 0.01;
      this.$refs.game.style.setProperty('--vh', `${newVh}px`);
    });
  },
  methods: {
    start() {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'Game' });
      } else {
        document.location.href = this.authUrl;
      }
    },
    glitch() {
      this.startMatrix = true;
      const tl = gsap.timeline({
        repeat: 1,
        onComplete: this.start,
      });

      tl.to('.glitch', 0.1, { color: 'rgba(255,255,255,1)' })
        .to('.glitch', 0.1, { skewX: 70, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { skewX: 0, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { opacity: 0 })
        .to('.glitch', 0.04, { opacity: 1 })
        .to('.glitch', 0.04, { x: -20 })
        .to('.glitch', 0.04, { x: 0 })
        .add('split', 0)
        .to('.top', 0.5, { x: -30, ease: Power4.easeInOut }, 'split')
        .to('.bottom', 0.5, { x: 30, ease: Power4.easeInOut }, 'split')
        .to(
          '.glitch',
          0.08,
          {
            '-webkit-filter': 'drop-shadow( 3px 3px 2px red)',
            filter: 'drop-shadow( 3px 3px 2px red)',
          },
          'split',
        )
        .to('.glitch-container', 0, { scale: 1.1 }, 'split')
        .to('.glitch-container', 0, { scale: 1 }, '+=0.02')
        .to('.glitch', 0.08, { '-webkit-filter': 'none', filter: 'none' }, '+=0.09')
        .to(
          '.glitch',
          0.03,
          {
            '-webkit-filter': 'drop-shadow( 3px 3px 2px green)',
            filter: 'drop-shadow( 3px 3px 2px green)',
          },
          'split',
        )
        .to('.glitch', 0.03, { '-webkit-filter': 'none', filter: 'none' }, '+=0.01')
        .to('.top', 0.2, { x: 0, ease: Power4.easeInOut })
        .to('.bottom', 0.2, { x: 0, ease: Power4.easeInOut })
        .to('.glitch', 0.02, { scaleY: 1.1, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { scaleY: 1, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { display: 'none' })
        .to('.broken', 0.04, { display: 'block' });
    },
  },
  computed: {
    authUrl() {
      return `${process.env.VUE_APP_SERVER_URL}/oauth/redirect?redirect_url=${window.location.origin}/game`;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isMobile() {
      return document.body.clientWidth < 768;
    },
  },
};
</script>
<style lang="scss">
$contentWidth: 1280px;

.window-ui {
  @apply relative;
  width: 100%;
  height: auto;
  min-height: 100vh;

  @media screen and (min-width: 768px) {
    height: 100vh;
    min-height: 800px;
  }

  &__container {
    @apply my-0 mx-auto border-l-2 border-r-2 border-b-2 border-solid;
    border-color: rgba(#f4f4f4, 0.3);
    max-width: $contentWidth;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    min-height: 800px;
  }

  &__header {
    @apply relative;
    width: 100%;

    &-container {
      @apply pr-80 border-t-2 border-b-2 border-solid relative;
      height: 68px;
      border-color: rgba(#f4f4f4, 0.3);
    }
  }

  &__logo {
    @apply border-r-2 border-solid;
    width: 216px;
    height: 100%;
    padding-top: 28px;
    padding-left: 74px;
    border-color: rgba(#f4f4f4, 0.3);
    color: rgba(255, 255, 255, 0.4);
  }

  &__menu {
    @apply absolute top-0;
    left: 216px;
    width: calc(100% - 216px);
    height: 100%;

    &-item {
      @apply absolute;
      top: calc(50% - 6px);
      &:nth-child(1) {
        left: 131px;
      }
      &:nth-child(2) {
        left: 241px;
      }
      &:nth-child(3) {
        left: 348px;
      }
      &:nth-child(4) {
        left: 499px;
      }
    }

    &-off {
      @apply absolute;
      top: calc(50% - 17px);
      left: calc(100% - 88px);
    }
  }

  &__wrapper {
    @apply flex items-center justify-center relative;
    height: calc(100% - 108px);
  }

  &__sidebar {
    @apply absolute left-0 top-0 ml-80 mt-88;

    &-list {
      @apply flex items-start;
      flex-direction: column;
    }

    &-item {
      @apply relative flex justify-center mt-60;
      width: 88px;
      & div {
        @apply flex justify-center;
      }

      &:first-child {
        @apply mt-0;
      }
    }
  }

  &__body {
    width: 320px;
    height: 620px;
  }

  &--mobile {
    min-height: 0;
    & .window-ui__container {
      border: none;
      min-height: 0;
    }
    & .window-ui__body {
      width: 100%;
      height: 100%;
    }
    & .window-ui__wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.bottom {
  font-size: 100px;
  -webkit-clip-path: inset(31% 0 0 0);
  clip-path: inset(58% 0 0 0);
}

.top {
  -webkit-clip-path: inset(0 0 41% 0);
  clip-path: inset(0 0 41%);
}

.redShadow {
  -webkit-filter: drop-shadow(3px 3px 2px red);
  filter: drop-shadow(3px 3px 2px red);
}
.greenShadow {
  -webkit-filter: drop-shadow(3px 3px 2px green);
  filter: drop-shadow(3px 3px 2px green);
}

.glitch {
  position: absolute;
  top: 0;
  color: rgba(255, 255, 255, 0.3);
}

.glitch-container {
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 482px;
  height: 100px;
  text-align: center;
  z-index: 2;
  font-size: 100px;
  text-transform: uppercase;
}

.broken {
  display: none;
  color: rgba(255, 255, 255, 0.3);
  &.visible {
    display: block;
  }
}

/*
    Mailer styles
*/
.mailer {
  @apply w-full h-full bg-cod-gray border-solid relative overflow-hidden;
  border-width: 3px;
  border-color: rgba(244, 244, 244, 0.2);

  @media screen and (min-width: 768px) {
    border-radius: 8px;
  }

  &__container {
    @apply relative h-full flex flex-col;

    /* &:after {
      @apply absolute top-0 left-0 bg-shark opacity-20;
      width: 100%;
      height: 100%;
      content: '';
    } */
  }

  &__header {
    @apply border-b border-solid relative flex-shrink-0;
    border-color: rgba(244, 244, 244, 0.2);
    height: 52px;
  }

  &__body {
    @apply relative flex-grow;
  }

  &__title {
    @apply flex items-center opacity-10 bg-shark;
    padding-top: 32px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 16px;

    &-part {
      @apply mr-12;
    }
  }

  &__messages {
    @apply opacity-10 bg-shark;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__list {
    @apply flex items-center -mx-12 max-h-full overflow-hidden;
    flex-direction: column;
  }

  &__item {
    @apply flex items-center px-12 border-b border-solid border-athens-gray;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;

    &:first-child {
      @apply border-t border-solid border-athens-gray;
    }

    &-avatar {
      @apply w-64 h-64 mr-16 rounded-full bg-silver;
    }

    &-message {
      &__title {
        @apply w-140 h-12 bg-silver mb-4;
      }

      &__subtitle {
        @apply w-92 h-12 bg-silver mb-12;
      }

      &__text {
        @apply w-32 h-12 bg-silver;
      }
    }

    &-author {
      @apply w-32 h-12 ml-auto mr-0 bg-silver self-start;
      margin-top: 6px;
    }
  }

  &__more {
    @apply font-navigo-regular text-18 leading-24 w-full my-12 text-center;
  }

  &__modal {
    @apply absolute top-0 left-0;
    flex-direction: column;
    margin-top: 40%;

    &-container {
      @apply mx-8 bg-cod-gray border border-solid rounded-2;
      border-color: rgba(244, 244, 244, 0.4);
    }

    &-header {
      @apply flex items-center px-12 py-12 border-b border-solid;
      border-color: rgba(244, 244, 244, 0.4);

      &__icon {
        @apply mr-12;
      }

      &__title {
        @apply font-navigo-medium text-14 leading-18 uppercase text-white;
      }
    }

    &-body {
      @apply mt-12 px-12 pb-16;

      &__author {
        @apply font-navigo-regular text-12 leading-16 mb-8;
      }

      &__from {
        @apply opacity-50 mr-8;
      }

      &__name {
        @apply opacity-60;
      }

      &__message {
        @apply font-navigo-regular text-14 leading-22 opacity-90;
      }
    }

    &-link {
      &.geecko-button {
        @apply mx-8 mt-12;
        width: calc(100% - 16px);
      }
    }
  }
}

.mailer-logo-mail {
  @apply absolute;
  top: 14px;
  left: 16px;
}
.mailer-logo-mail-title {
  @apply absolute;
  top: 19px;
  left: 48px;
}
.mailer-logo-mail-close {
  @apply absolute;
  top: 16px;
  left: calc(100% - 32px);
}
</style>
