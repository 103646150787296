<template>
  <div class="social-container">
    <a
      :class="`social-item social-item--size-${size}`"
      v-for="social in socialItems"
      :key="social.id"
      :href="social.link"
      target="_blank"
    >
      <component :is="`icon-${social.id}`"></component>
    </a>
  </div>
</template>

<script>
import IconHabr from './Icons/IconHabr.vue';

export default {
  name: 'CrocSocialNetworkLinks',
  components: {
    IconHabr,
  },
  data() {
    return {
      socialItems: [
        { id: 'habr', link: ' https://habr.com/ru/company/raiffeisenbank' },
      ],
    };
  },
  props: {
    size: { type: String, default: 'md' },
  },
};
</script>

<style lang="scss">
.social {
  &-container {
    @apply flex flex-wrap w-auto -mx-8;
  }
  &-item {
    @apply flex items-center justify-center opacity-80 text-white sm:px-8;
    box-sizing: border-box;
    height: var(--size);
    min-width: var(--size);
    width: max-content;
    background: rgba(244, 244, 244, 0.08);
    border-radius: 2px;
    &:hover {
      @apply opacity-100;
    }
    &--size {
      &-md {
        @apply m-4 py-12 rounded-4;
        --size: 32px;
        & svg {
          height: 17px;
        }

        @media screen and (min-width: 768px) {
          --size: 40px;
          & svg {
            height: auto;
          }
        }
      }
      &-sm {
        @apply m-4 py-4 rounded-2;
        --size: 32px;
        & svg {
          height: 16px;
        }
      }
    }
  }
}
</style>
