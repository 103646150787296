<template>
  <div class="landing-attention">
    <div class="w-288">
      <Dialog>
        <div class="text-center">
          <icon-rotation />
          <h3 class="text-promary text-18 leading-28 mb-8 mt-24 uppercase font-navigo-medium">
            Переверни обратно
          </h3>
          <p class="text-16 leading-24 font-navigo-regular opacity-80">
            Дружище, так не пойдет. Если хочешь продолжить играть, верни телефон в вертикальное
            положение
          </p>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import IconRotation from '@/components/Icons/IconRotation.vue';

export default {
  components: {
    Dialog,
    IconRotation,
  },
  name: 'TurnDeviceAttention',
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang='scss' scoped>
</style>
