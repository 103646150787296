<!-- eslint-disable max-len -->
<template>
  <svg width="57" height="24" viewBox="0 0 57 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6091 19.1418H17.539L17.0262 18.3661L12.0247 10.7992L16.9834 3.18872L17.487 2.41577H16.5645H12.7893H12.5161L12.3685 2.64575L9.352 7.34678L6.37625 2.64824L6.22902 2.41577H5.95384H2.04468H1.11781L1.62682 3.19036L6.58439 10.7345L1.58181 18.3677L1.07451 19.1418H2H5.81981H6.09368L6.24114 18.911L9.26125 14.1848L12.3018 18.9123L12.4494 19.1418H12.7223H16.6091Z" stroke="#F4F4F4" stroke-opacity="0.8"/>
    <path d="M17.6662 10.3881L17.5753 10.9658H18.1601H21.1981H21.5715L21.6775 10.6079C21.7807 10.2596 22.1312 9.84891 23.0745 9.84891C23.5432 9.84891 23.8953 9.96896 24.1197 10.1507C24.3303 10.3213 24.4733 10.581 24.4733 10.9796V11.0157H22.4491C20.9057 11.0157 19.5994 11.3714 18.6678 12.0989C17.7197 12.8392 17.2134 13.9233 17.2134 15.2462C17.2134 16.5466 17.7186 17.6032 18.5598 18.3279C19.3926 19.0454 20.5207 19.4096 21.7342 19.4096C22.8645 19.4096 23.8682 19.1034 24.652 18.5537V18.6416V19.1416H25.152H28.1676H28.6676V18.6416V11.0913C28.6676 9.55618 28.1195 8.33923 27.12 7.51648C26.1345 6.70528 24.7683 6.32471 23.2086 6.32471C19.986 6.32471 18.023 8.12007 17.6662 10.3881ZM22.6278 15.8854C22.2002 15.8854 21.9062 15.7803 21.731 15.6443C21.5697 15.519 21.4747 15.3431 21.4747 15.0898C21.4747 14.8388 21.5753 14.6565 21.756 14.5222C21.9512 14.3772 22.2669 14.2719 22.6948 14.2719H24.4733V14.2856C24.4733 14.8064 24.2663 15.1907 23.95 15.4536C23.6241 15.7245 23.1565 15.8854 22.6278 15.8854Z" stroke="#F4F4F4" stroke-opacity="0.8"/>
    <path d="M41.187 5.33161L41.6141 5.26872V4.83694V2V1.41023L41.0323 1.50674L36.319 2.28857L36.3186 2.28864C33.931 2.68658 32.2979 3.69947 31.2847 5.33602C30.2896 6.94327 29.9365 9.0837 29.9365 11.6501C29.9365 14.0025 30.5069 15.9459 31.6474 17.3096C32.7985 18.6861 34.4789 19.41 36.5572 19.41C40.3817 19.41 42.7311 16.4623 42.7311 13.0127C42.7311 11.1866 42.2002 9.63077 41.2238 8.5242C40.2423 7.41183 38.8453 6.79413 37.205 6.79413C36.1132 6.79413 35.1209 7.04464 34.2958 7.56378C34.4092 7.31133 34.546 7.09851 34.7072 6.91819C35.1216 6.45481 35.7601 6.13671 36.7878 5.9792C36.7883 5.97913 36.7887 5.97906 36.7892 5.97899L41.187 5.33161ZM36.4008 15.6847C35.7007 15.6847 35.1887 15.4109 34.8396 14.9659C34.4798 14.507 34.2649 13.8271 34.2649 12.9904C34.2649 12.1968 34.4858 11.5608 34.8478 11.1322C35.2017 10.7131 35.7228 10.4524 36.4231 10.4524C37.089 10.4524 37.5799 10.7146 37.9157 11.1393C38.2617 11.5768 38.4697 12.2233 38.4697 13.0127C38.4697 13.8054 38.2656 14.4841 37.914 14.9515C37.5735 15.4039 37.0779 15.6847 36.4008 15.6847Z" stroke="#F4F4F4" stroke-opacity="0.8"/>
    <path d="M48.1909 22.9081V18.6777C48.8835 19.1361 49.7877 19.4096 50.8406 19.4096C52.3965 19.4096 53.8076 18.7941 54.8247 17.6503C55.8392 16.5093 56.4337 14.8758 56.4337 12.8783C56.4337 10.8493 55.8403 9.21059 54.8374 8.07186C53.832 6.93031 52.4427 6.32471 50.93 6.32471C49.7173 6.32471 48.7623 6.6719 48.0569 7.19519V7.09276V6.59276H47.5569H44.4296H43.9296V7.09276V22.9081V23.4081H44.4296H47.6909H48.1909V22.9081ZM50.1481 15.595C49.536 15.595 49.0501 15.3534 48.7064 14.925C48.3543 14.4859 48.1239 13.8133 48.1239 12.923V12.8337C48.1239 11.9433 48.3544 11.2639 48.708 10.8186C49.0531 10.384 49.5392 10.1393 50.1481 10.1393C50.7529 10.1393 51.2322 10.386 51.573 10.8273C51.9232 11.2808 52.15 11.9731 52.15 12.8783C52.15 13.7836 51.9233 14.4691 51.5744 14.9163C51.2351 15.3513 50.7561 15.595 50.1481 15.595Z" stroke="#F4F4F4" stroke-opacity="0.8"/>
  </svg>
</template>

<script>
export default {
  name: 'IconHabr',
  components: {},
  data() {
    return {};
  },
};
</script>
