<template>
  <div class="simple-card__scene">
    <div
      class="simple-card"
      ref="cardMain"
      :class="{
        'simple-card--flipped': isFlipped,
        'simple-card--unflip': isUnflip,
        'simple-card--no-border': noBorder,
      }"
    >
      <div
        class="simple-card-face simple-card-backing"
        :class="{ 'simple-card-face--blue': isBlue }"
        :style="computedStyle"
      ></div>
      <div class="simple-card-face simple-card-front" ref="front" :style="computedStyle">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import gsap from 'gsap';

export default {
  props: {
    noBorder: Boolean,
    isBlue: Boolean,
  },
  data() {
    return {
      isFlipped: false,
      isUnflip: false,
      height: 289,
    };
  },
  mounted() {
    const resizeOb = new ResizeObserver((entries) => {
      const rect = entries[0].borderBoxSize;
      const { blockSize } = rect[0];
      if (blockSize && blockSize > 288) {
        this.height = blockSize;
      } else {
        this.height = 290;
      }
    });

    if (this.$slots.default && this.$slots.default[0] && this.$slots.default[0].elm) {
      resizeOb.observe(this.$slots.default[0].elm);
    }
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.$refs.cardMain.style.backfaceVisibility = 'hidden';
    }
  },
  computed: {
    computedStyle() {
      return {
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    flip() {
      this.isFlipped = true;
    },
    unflip() {
      this.isUnflip = true;
      setTimeout(() => {
        this.isFlipped = false;
        this.isUnflip = false;
      }, 500);
    },
    appear(cb, duration = 0.5) {
      gsap.set(this.$el, { x: 0, y: 0, rotation: 0 });
      gsap.fromTo(
        this.$el,
        {
          scale: 0,
          autoAlpha: 0,
        },
        {
          duration,
          scale: 1,
          autoAlpha: 1,
          onComplete: () => {
            cb(this);
          },
        },
      );
    },
    disappear(cb, duration = 1) {
      gsap.to(this.$el, {
        duration,
        rotation: -93,
        x: -1000,
        y: 1000,
        onComplete: () => {
          cb(this);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-card {
  &__scene {
    width: 100%;
    perspective: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    --border-radius: 4px;
    --border: 1px solid rgba(244, 244, 244, 0.4);
  }
}

.simple-card-front {
  background-size: contain;
  border-radius: var(--border-radius);
}
.simple-card-backing {
  background: rgba(20, 20, 20, 0.88);
  transform: rotateY(180deg);
  border-radius: var(--border-radius);

  &--blue {
    background: linear-gradient(0deg, rgba(81, 135, 255, 0.04), rgba(81, 135, 255, 0.04)), #141414;
  }
}
.simple-card-face {
  position: absolute;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: var(--border-radius);
  z-index: 1;
  border: var(--border);

  &.simple-card-backing {
    z-index: 5;
  }

  &.simple-card-front {
    z-index: 4;
  }
}

.simple-card {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 288px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  background-repeat: no-repeat;
  border-radius: 4px;
  &.simple-card--flipped {
    animation: cardFlip 0.35s forwards linear;

    &.simple-card-backing {
      z-index: 4;
    }

    &.simple-card-front {
      z-index: 5;
    }
  }
  &.simple-card--unflip {
    animation: cardUnFlip 0.35s forwards linear;
  }

  &--no-border {
    .simple-card-face {
      border: none;
    }

    .simple-card-backing {
      border: var(--border);
    }

    .simple-card-face--blue {
      background: linear-gradient(0deg, rgba(81, 135, 255, 0.04), rgba(81, 135, 255, 0.04)),
        #141414;
      border: 1px solid rgba(81, 135, 255, 0.4);
    }
  }
}

@keyframes cardFlip {
  0% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg);
  }
}

@keyframes cardUnFlip {
  0% {
    transform: rotateZ(0deg) rotateY(0deg);
  }
  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }
  100% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
}
</style>
