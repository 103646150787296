<!-- eslint-disable max-len -->
<template>
  <svg width="83" height="39" viewBox="0 0 83 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9619 14.4087L11.5108 14.9805L11.3975 16.4762L3.84861 15.9044L3.9619 14.4087ZM1.3323 15.7138L3.84861 15.9044L3.39541 21.8873L0.879109 21.6966L1.3323 15.7138ZM11.3975 16.4762L13.9138 16.6668L13.4606 22.6497L10.9443 22.4591L11.3975 16.4762ZM3.39541 21.8873L10.9443 22.4591L10.831 23.9548L3.28211 23.383L3.39541 21.8873Z"
      fill="currentColor"
    />
    <path
      d="M23.7298 23.4276L26.2461 23.6182L26.1328 25.1139L18.5839 24.5421L18.6972 23.0463L21.2135 23.237L21.3268 21.7412L18.8105 21.5506L18.9238 20.0549L21.4401 20.2455L21.5534 18.7498L19.0371 18.5592L19.1504 17.0635L21.6667 17.2541L21.78 15.7584L44.1777 3.99998L24.183 17.4447L26.6993 17.6353L26.586 19.131L24.0697 18.9404L23.9564 20.4361L26.4727 20.6267L26.3594 22.1224L23.8431 21.9318L23.7298 23.4276ZM19.0371 18.5592L18.9238 20.0549L16.4075 19.8643L16.5208 18.3686L19.0371 18.5592ZM28.8757 22.3131L28.7624 23.8088L26.2461 23.6182L26.3594 22.1224L28.8757 22.3131Z"
      fill="currentColor"
    />
    <path
      d="M42.0011 18.7944L34.4522 18.2226L34.5655 16.7269L42.1144 17.2987L42.0011 18.7944ZM34.4522 18.2226L33.999 24.2054L31.4827 24.0148L31.9358 18.032L34.4522 18.2226ZM44.5174 18.985L44.1775 23.4721L36.6286 22.9003L36.8552 19.9089L39.3715 20.0995L39.2582 21.5952L41.7745 21.7858L42.0011 18.7944L44.5174 18.985ZM33.999 24.2054L44.0642 24.9679L43.9509 26.4636L33.8857 25.7011L33.999 24.2054Z"
      fill="currentColor"
    />
    <path
      d="M47.3509 17.6953L49.8672 17.8859L49.6406 20.8774L57.1895 21.4492L57.4161 18.4578L59.9324 18.6484L59.2526 27.6227L56.7363 27.4321L57.0762 22.9449L49.5273 22.3731L49.1874 26.8602L46.6711 26.6696L47.3509 17.6953Z"
      fill="currentColor"
    />
    <path
      d="M65.169 19.045L72.7179 19.6169L72.6046 21.1126L65.0557 20.5408L65.169 19.045ZM62.5394 20.3501L65.0557 20.5408L64.7158 25.0279L72.2647 25.5997L72.6046 21.1126L70.39 26.962L80 38.5L72.0381 28.5911L72.1514 27.0954L64.6025 26.5236L64.4892 28.0193L61.9729 27.8287L62.5394 20.3501Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiWindowsBroken',
};
</script>
