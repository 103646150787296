<template>
  <section class="leader-board">
    <div class="flex items-end justify-center mb-24">
      <h2 class="leader-board__title leader-board__shadowed">
        Таблица лидеров
      </h2>
      <span
        v-if="currentTopDate"
        class="leader-board__shadowed ml-12"
      >{{currentTopDate}}</span>
    </div>
    <div class="tabs-container">
      <button
        class="top-tab"
        @click="handleShow('ios')"
        :class="{current: currentTopTab === 'ios'}"
      >
        iOS
      </button>
      <button
        class="top-tab"
        @click="handleShow('android')"
        :class="{current: currentTopTab === 'android'}"
      >
        Android
      </button>
    </div>
    <div class="leader-board__loader" v-if="playersLoading">
        <div class="leader-board__loader-container">
          <div class="leader-board__loader-circle"></div>
          <div class="leader-board__loader-circle"></div>
          <div class="leader-board__loader-circle"></div>
        </div>
    </div>
    <div class="leader-board__container" v-else>
      <div class="leader-board__table" v-if="currentTopPlayers">
        <div class="leader-board__table-header">
          <div class="leader-board__table-row">
            <div class="leader-board__table-col leader-board__table-col--md">
              <p class="leader-board__table-text">N</p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--lg">
              <p class="leader-board__table-text">Игрок</p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--sm">
              <p class="leader-board__table-text">Очки</p>
            </div>
          </div>
        </div>

        <div class="leader-board__table-body">
          <div
            class="leader-board__table-row"
            v-for="({rank, score, time, user}, idx) in currentTopPlayers"
            :key="idx"
            :class="{ current: user.username === (currentUser && currentUser.username) }"
          >
            <div class="leader-board__table-col leader-board__table-col--md">
              <p
                class="leader-board__table-text
                leader-board__shadowed
                font-navigo-black
                text-16 sm:text-24"
              >
                {{ rank }}
              </p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--lg">
              <p class="leader-board__table-text font-navigo-medium text-14 sm:text-18">
                {{ user.username }}
              </p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--sm">
              <p
                class="leader-board__table-text
                font-navigo-medium
                text-18 sm:text-24
                text-primary"
              >
                {{ score }}
              </p>
            </div>
          </div>
          <!-- <div
            class="leader-board__table-row leader-board__table-row--current"
            v-if="
              currentUser &&
              currentUser.id &&
              gameInfo &&
              gameInfo.rank &&
              currentTopPlayers &&
              !currentTopPlayers.find(
                (player) => player.username === (currentUser && currentUser.username)
              ) &&
              currentTopTab === currentTopPlayers.mobile_platform
            "
          >
            <div class="leader-board__table-col leader-board__table-col--md">
              <p
                class="leader-board__table-text
                leader-board__shadowed
                font-navigo-black
                text-24"
              >
                {{ gameInfo.rank }}
              </p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--lg">
              <p class="leader-board__table-text font-navigo-medium text-18">
                {{ currentUser.username  }}
              </p>
            </div>
            <div class="leader-board__table-col leader-board__table-col--sm">
              <p class="leader-board__table-text font-navigo-medium text-24 text-primary">
                {{ gameInfo.score }}
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="leader-board__nothing text-18 text-center" v-else>
        Нет результатов
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'TopTable',
  async created() {
    await this.$store.dispatch('leaderBoard/fetchLeaderboard');
  },
  data() {
    return {
      currentTopTab: 'android',
    };
  },
  computed: {
    ...mapState(['leaderBoard']),
    players() {
      return this.leaderBoard.players;
    },
    playersError() {
      return this.leaderBoard.error;
    },
    playersLoading() {
      return this.leaderBoard.loading;
    },
    currentUser() {
      return this.$store.getters['user/user'];
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    currentTopPlayers() {
      return this.leaderBoard.leaderboard[this.currentTopTab];
    },
    currentTopDate() {
      if (!this.leaderBoard.leaderboard.date) {
        return null;
      }
      return new Date(this.leaderBoard.leaderboard.date).toLocaleDateString();
    },
  },
  methods: {
    handleShow(val) {
      this.currentTopTab = val;
    },
  },
  mounted() {
    const userMobilePlatform = this.currentUser?.mobile_platform;
    if (userMobilePlatform) {
      this.currentTopTab = userMobilePlatform;
    }
  },
};
</script>

<style lang="scss" scoped>
@mixin animate($name, $duration, $iteration, $direction, $animation-timing-function) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: $iteration;
  animation-direction: $direction;
  animation-timing-function: $animation-timing-function;
};

.tabs-container {
  @apply mx-auto flex justify-between mb-32;
  max-width: 780px;
}

.top-tab {
  @apply font-navigo-medium;
  height: 48px;
  border: 1px solid #505050;
  border-radius: 2px;
  width: 49%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  &.current {
    border: 1px solid #FEE600;
    background: #141414;
    color: #FEE600;
  }
}

.leader-board {
  &__container {
    @apply w-full my-0 mx-auto;
    max-width: 780px;
  }

  /* &__title {
    @apply font-navigo-black text-24 sm:text-32 leading-40 text-center uppercase;
  } */

  &__shadowed {
    @apply text-black font-navigo-black text-24 sm:text-32 leading-40 text-center uppercase;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  &__table {
    @apply w-full;

    &-row {
      @apply flex items-center w-full sm:pl-52
              sm:pr-48 pl-16 pr-16 border border-solid border-dove-gray;
      background-color: rgba(0, 0, 0, 0.6);
    }

    &-col {
      &--sm {
        flex-basis: 5%;
      }

      &--md {
        flex-basis: 15%;
      }

      &--lg {
        flex-basis: 80%;
      }
    }

    &-header {
      @apply mb-8;
    }
    &-header & {
      &-row {
        @apply py-4 sm:py-8;
      }

      &-text {
        @apply font-navigo-regular leading-16 text-white opacity-70;
        font-size: 10px;

        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }

    &-body & {
      &-row {
        @apply py-16 sm:py-20;

        &--current {
          @apply border border-solid border-white;
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      &-text {
        @apply leading-20;
      }
    }
  }

  &__loader {
    @apply w-full;

    &-container {
      @apply flex items-center justify-center my-20;
    }

    &-circle {
      @apply w-16 h-16 rounded-full bg-white mx-4;
      @include animate(Bounce, .5s, infinite, alternate, linear);

      &:nth-child(2) {
        animation-delay: .2s;
      }

      &:nth-child(3) {
        animation-delay: .3s;
      }
    }
  }
}

@keyframes Bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-10px);
  }
}
</style>
