<style lang='scss'>
.martix-rain {
  @apply absolute;
  left: 0;
  top: 52px;
}
</style>

<template>
  <canvas class="martix-rain" ref="matrixRain"></canvas>
</template>

<script>
import { scaleCanvas } from '@/utils';

const MATRIX_STEP = 50;
const SIZE = 20;

const TWEEN = require('tween.js');

const END_OF_GAME_FADE_IN = 3000;

export default {
  name: 'MatrixRain',
  components: {},
  data() {
    return {};
  },
  props: {
    launchMatrix: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {},
  watch: {
    launchMatrix(val) {
      if (val) {
        this.startMatrix();
      }
    },
  },
  methods: {
    matrixFadeOut() {
      this.nextClicked = true;
      const opacityTweenFrom = { opacity: 1 };

      new TWEEN.Tween(opacityTweenFrom)
        .to(
          {
            opacity: 0,
          },
          END_OF_GAME_FADE_IN,
        )
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          if (this.$refs.matrixRain) {
            this.$refs.matrixRain.style.opacity = `${opacityTweenFrom.opacity}`;
          }
        })
        .onComplete(() => {
          if (this.$refs.matrixRain) {
            this.$refs.matrixRain.style.pointerEvents = 'none';
          }
        })
        .start();
    },
    startMatrix() {
      setTimeout(this.matrixFadeOut, 2000);
      const c = this.$refs.matrixRain;
      const ctx = c.getContext('2d');

      c.height = window.innerHeight;
      c.width = window.innerWidth;

      scaleCanvas(c, ctx, c.width, c.height);

      const letters = '日ﾊﾐﾋｰｳｼﾅﾓﾆｻﾜﾂｵﾘｱﾎﾃﾏｹﾒｴｶｷﾑﾕﾗｾﾈｽﾀﾇﾍｦｲｸｺｿﾁﾄﾉﾌﾔﾖﾙﾚﾛﾝ012345789Z:・."=*+-<>¦｜ç'.split(
        '',
      );
      const w = window.innerWidth;
      const h = window.innerHeight;
      const cols = Math.floor(w / SIZE) + 1;
      const ypos = new Array(cols).fill(0);

      ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
      ctx.fillRect(0, 0, w, h);

      function matrix() {
        ctx.fillStyle = '#0001';
        ctx.fillRect(0, 0, w, h);

        ctx.fillStyle = '#F4F4F4';
        ctx.font = '15pt Navigo-thin';

        ypos.forEach((y, ind) => {
          const text = letters[Math.floor(Math.random() * letters.length)];
          const x = ind * 20;
          ctx.fillText(text, x, y);
          if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
          else ypos[ind] = y + 20;
        });
      }

      this.matrixInterval = setInterval(matrix, MATRIX_STEP);
    },
  },
  computed: {},
};
</script>
