<template>
  <div class="hud">
    <div class="hud-content">
      <div class="level__container">
        {{ answeredQuestions > questionQuantity ? questionQuantity : answeredQuestions }} /
        {{ questionQuantity }}
      </div>
      <counter v-if="gameInfo && gameInfo.serial === 1" />
    </div>
  </div>
</template>

<script>
import Counter from './UI/Counter.vue';

export default {
  components: { Counter },
  props: {
    answeredQuestions: { type: Number, default: 0 },
  },
  name: 'HudPanel',
  data() {
    return {
      numberOfLevels: 4,
    };
  },
  computed: {
    questionQuantity() {
      return this.gameInfo?.stages.reduce((res, curr) => {
        const newVal = res + curr.total_questions;
        return newVal;
      }, 0);
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.hud {
  @apply absolute top-28;
  width: calc(100% - 20px);
  left: 10px;
  &-content {
    @apply flex justify-between;
  }
}
.level {
  &__container {
    @apply font-navigo-regular text-12 text-center relative;
    margin: 0 -1px 0 -1px;
    --height: 32px;
    height: var(--height);
    line-height: calc(var(--height) - 2px);
    color: #e2e2e2;
    border: 1px solid #68676c;
    border-radius: 2px;
    &:after {
      @apply absolute font-navigo-medium leading-16 uppercase;
      font-size: 10px;
      bottom: calc(100% + 4px);
      width: max-content;
      content: 'ВОПРОС';
      left: 0;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
    width: 67px;
    letter-spacing: 0.1px;
  }
}
</style>
