<!-- eslint-disable max-len -->
<template>
  <svg width="61" height="9" viewBox="0 0 61 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.29156 0H6.61156V1.44H2.29156V0ZM0.851562 1.44H2.29156V7.2H0.851562V1.44ZM6.61156 1.44H8.05156V2.88H6.61156V1.44ZM6.61156 5.76H8.05156V7.2H6.61156V5.76ZM2.29156 7.2H6.61156V8.64H2.29156V7.2Z"
      fill="currentColor"
    />
    <path d="M16.8083 8.64H15.3683V1.44H11.0483V8.64H9.60828V0H16.8083V8.64Z" fill="currentColor" />
    <path
      d="M18.365 0H24.125V1.44H19.805V5.76H24.125V7.2H19.805V8.64H18.365V0ZM24.125 1.44H25.565V5.76H24.125V1.44Z"
      fill="currentColor"
    />
    <path
      d="M28.5617 0H32.8817V1.44H28.5617V0ZM27.1217 1.44H28.5617V5.76H32.8817V1.44H34.3217V8.64H32.8817V7.2H28.5617V8.64H27.1217V1.44Z"
      fill="currentColor"
    />
    <path
      d="M35.8784 0H41.6384V1.44H37.3184V2.88H41.6384V4.32H37.3184V7.2H41.6384V8.64H35.8784V0ZM41.6384 1.44H43.0784V2.88H41.6384V1.44ZM41.6384 4.32H43.0784V7.2H41.6384V4.32Z"
      fill="currentColor"
    />
    <path
      d="M48.9552 4.32H46.0752V8.64H44.6352V0H46.0752V2.88H48.9552V4.32ZM51.8352 1.44H50.3952V0H51.8352V1.44ZM50.3952 2.88H48.9552V1.44H50.3952V2.88ZM48.9552 5.76V4.32H50.3952V5.76H48.9552ZM50.3952 5.76H51.8352V8.64H50.3952V5.76Z"
      fill="currentColor"
    />
    <path
      d="M54.8319 0H59.1519V1.44H54.8319V0ZM53.3919 1.44H54.8319V5.76H59.1519V1.44H60.5919V8.64H59.1519V7.2H54.8319V8.64H53.3919V1.44Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiRef',
};
</script>
