<template>
  <div class="landing text-left">
    <turn-device-attention />
    <div class="bg-mars">
      <section class="container mx-auto pb-212 relative overflow-hidden">
        <div class="flex justify-between items-center pt-12 sm:pt-32">
          <span class="company-icon">
            <icon-startup-village />
          </span>
          <SocialNetworkLinks />
        </div>
        <div class="text-center">
          <h1 class="landing-title">{{ title }}</h1>
          <img class="landing-raiffer-logo" src="/images/raiffer@2x.webp" alt="Raiffer" />
          <p class="landing-subtitle">{{ subtitle }}</p>
          <a href="#game" @click.prevent="goToGame" class="btn mb-60"> Начать игру </a>
          <!--<a
          class="btn btn--red btn--size-lg uppercase mb-76 ml-32 cursor-pointer"
          @click="resetGame">
            Сброс
          </a>-->
          <div class="question" ref="question">
            <div class="question__content">
              <div class="question__answers">
                <div class="question__answers-item question__answers-item--no">Нет</div>
                <div class="question__answers-item question__answers-item--yes">Да</div>
              </div>
              <span class="question__answers-text"> {{ question }} </span>
            </div>
          </div>
          <div class="lights"></div>
        </div>
      </section>
    </div>
    <div ref="gameOuter" id="gameOuter" class="game">
      <WindowUi>
        <!-- <template slot-scope="{glitch}">
          <Mailer @start="glitch" />
        </template> -->
      </WindowUi>
    </div>
    <div class="landing__combiner mt-16 sm:mt-76">
      <div class="container mx-auto pt-28 sm:pt-12 mb-44" ref="topTable">
        <TopTable />
      </div>
      <div class="footer-container">
        <div class="container mx-auto bg-none">
          <footer-content />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialNetworkLinks from '../components/SocialNetworkLinks.vue';
import WindowUi from '../components/LandingPage/WindowUi.vue';
import FooterContent from '../components/LandingPage/FooterContent.vue';
import IconStartupVillage from '../components/Icons/IconStartupVillage.vue';
import TurnDeviceAttention from '../components/LandingPage/TurnDeviceAttention.vue';
import TopTable from '../components/LandingPage/TopTable.vue';

export default {
  components: {
    SocialNetworkLinks,
    WindowUi,
    FooterContent,
    IconStartupVillage,
    TurnDeviceAttention,
    TopTable,
  },
  name: 'LandingPage',
  data() {
    return {
      title: 'Welcome to',
      subtitle: 'Tinder, где найти мэтч могут только Mobile-разработчики',
      question: 'Модель MVC - это Mongoose, View, Controller?',
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    quizComplete() {
      return this.gameInfo?.stages.every(
        (item) => item.answered_questions === item.total_questions,
      );
    },
  },
  methods: {
    async resetGame() {
      await this.$store.dispatch('game/restartGame');
      window.location.reload();
    },
    goToGame() {
      document.getElementById('gameOuter').scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    // скролл к игре при перезагрузке страницы для авторизованных
    if (this.user && this.user.id) {
      if (this.quizComplete) {
        this.$refs.topTable.scrollIntoView();
      } else {
        this.$refs.gameOuter.scrollIntoView();
      }
    }
  },
};
</script>

<style lang="scss">
.container {
  @apply relative z-10;
}

.bg-mars {
  background-image: url('/images/welcome-screen-bg-mobile.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (min-width: 768px) {
    background-image: url('/images/welcome-screen-bg.png');
    background-size: 1400px;
  }
}

.landing-mars {
  @apply absolute;
  height: 692px;
  top: 94px;
  right: calc(50% + 279px);
  z-index: 1;
}

.landing {
  @apply bg-contain leading-56 text-36 text-white font-ubuntumono-bold bg-black;
  width: 100%;
  overflow: hidden;
}

.landing-h2 {
  @apply text-24 leading-34 sm:text-32 sm:leading-36 uppercase font-navigo-medium;
  color: rgba(255, 255, 255, 0.8);
}

.company-icon {
  & svg {
    @apply w-144 sm:w-164;
  }
}

.landing-title {
  @apply text-white text-52 sm:text-78 leading-48 sm:leading-72
          my-0 mx-auto mt-88 sm:mt-68 font-navigo-black;
  max-width: 710px;
}

.landing-raiffer-logo {
  @apply mt-8 w-260 sm:w-348 inline;
}

.landing-subtitle {
  @apply my-0 mx-auto mt-16 sm:mt-24 mb-32 sm:mb-44 text-16 sm:text-18 leading-24
    font-navigo-regular opacity-90;
  max-width: 350px;
}

.landing__combiner {
  background-image: url('/images/combiner-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (min-width: 768px) {
    background-size: auto;
  }
}

.btn {
  @apply text-16 leading-48 rounded-2 bg-primary inline-block border-none px-48
    font-navigo-medium uppercase w-auto text-center text-shark;
  color: #010101;
  transition: 0.25s;

  &:hover {
    @apply bg-golden-fizz;
  }

  &:active {
    @apply bg-sahara;
  }

  &:disabled {
    @apply cursor-not-allowed;
    color: rgba(1, 1, 1, 0.4);
    background: #646879;
  }

  &--tg {
    &:before {
      content: '';
      @apply absolute bg-contain;
      width: 16px;
      height: 16px;
      top: calc(50% - 16px / 2);
      left: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99577 0.333313C5.67723 0.333313 4.3883 0.724306 3.29197 1.45685C2.19564 2.18939 1.34116 3.23058 0.836574 4.44876C0.33199 5.66693 0.199968 7.00738 0.457203 8.30058C0.714437 9.59379 1.34938 10.7817 2.28173 11.714C3.21408 12.6464 4.40196 13.2813 5.69517 13.5385C6.98838 13.7958 8.32882 13.6638 9.54699 13.1592C10.7652 12.6546 11.8064 11.8001 12.5389 10.7038C13.2714 9.60745 13.6624 8.31852 13.6624 6.99998C13.6625 6.12448 13.4901 5.25754 13.1551 4.44867C12.8201 3.6398 12.3291 2.90484 11.71 2.28577C11.0909 1.6667 10.3559 1.17564 9.54708 0.840635C8.73821 0.505629 7.87127 0.33324 6.99577 0.333313ZM9.11544 10.435C9.09049 10.4972 9.05252 10.5533 9.00409 10.5997C8.95565 10.646 8.89787 10.6815 8.83461 10.7037C8.77135 10.7259 8.70408 10.7343 8.63731 10.7284C8.57053 10.7224 8.5058 10.7023 8.44744 10.6693L6.63766 9.26304L5.47612 10.3346C5.44917 10.3546 5.41764 10.3674 5.38444 10.3719C5.35123 10.3764 5.31742 10.3726 5.28612 10.3606L5.50878 8.3683L5.51591 8.37395L5.52046 8.33462C5.52046 8.33462 8.77713 5.36961 8.9098 5.24329C9.04411 5.11729 8.9998 5.08995 8.9998 5.08995C9.00744 4.93627 8.75878 5.08995 8.75878 5.08995L4.44377 7.86598L2.64678 7.25398C2.64678 7.25398 2.37078 7.15498 2.34478 6.93731C2.31744 6.72131 2.65577 6.60398 2.65577 6.60398L9.80044 3.76564C9.80044 3.76564 10.3878 3.50396 10.3878 3.93731L9.11544 10.435Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='0.329101' y1='13.6667' x2='13.6624' y2='0.999984' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300A460'/%3E%3Cstop offset='1' stop-color='%2373E553'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
}

.tg-btn {
  @apply text-12 sm:text-14 rounded-2 border border-primary pr-20 pl-44 relative
    inline-flex items-center font-navigo-regular uppercase;
  --height: 40px;
  height: var(--height);
  background: transparent;

  &:after {
    content: '';
    @apply absolute bg-contain bg-no-repeat;
    --size: 20px;
    width: var(--size);
    height: var(--size);
    top: calc(50% - var(--size) / 2);
    left: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3135 1.81677C8.51674 1.81677 6.76032 2.34958 5.26637 3.34781C3.77241 4.34604 2.60801 5.76487 1.92041 7.42486C1.23282 9.08486 1.05291 10.9115 1.40344 12.6737C1.75398 14.436 2.6192 16.0547 3.88971 17.3252C5.16022 18.5957 6.77895 19.4609 8.54119 19.8115C10.3034 20.162 12.1301 19.9821 13.7901 19.2945C15.4501 18.6069 16.8689 17.4425 17.8671 15.9486C18.8653 14.4546 19.3981 12.6982 19.3981 10.9014C19.3982 9.70836 19.1633 8.52699 18.7068 7.42475C18.2503 6.3225 17.5811 5.32098 16.7375 4.47738C15.8939 3.63377 14.8924 2.96461 13.7902 2.5081C12.6879 2.05159 11.5066 1.81667 10.3135 1.81677ZM13.202 15.5822C13.168 15.667 13.1162 15.7436 13.0502 15.8067C12.9842 15.8699 12.9055 15.9182 12.8193 15.9484C12.7331 15.9787 12.6414 15.9901 12.5504 15.982C12.4594 15.974 12.3712 15.9465 12.2917 15.9016L9.82552 13.9853L8.24269 15.4455C8.20596 15.4727 8.163 15.4901 8.11776 15.4963C8.07251 15.5025 8.02644 15.4972 7.98378 15.4809L8.28721 12.766L8.29692 12.7737L8.30312 12.7201C8.30312 12.7201 12.741 8.67971 12.9217 8.50757C13.1048 8.33587 13.0444 8.29862 13.0444 8.29862C13.0548 8.08919 12.716 8.29862 12.716 8.29862L6.83592 12.0815L4.38717 11.2475C4.38717 11.2475 4.01106 11.1126 3.97563 10.816C3.93839 10.5217 4.39942 10.3618 4.39942 10.3618L14.1354 6.49398C14.1354 6.49398 14.9358 6.13739 14.9358 6.72792L13.202 15.5822Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  &:hover {
    background: rgba(255, 236, 58, 0.1);
  }

  &:hover:active {
    opacity: 0.7;
  }

  &--size-sm {
    --height: 40px;
  }

  &__text {
    @apply leading-20;
    margin-top: 2px;
  }
}
.question {
  @apply text-center rounded-16 absolute mx-auto;
  left: 50%;
  width: 100%;
  max-width: 278px;
  background-color: rgba(#141414, 0.6);
  border: 1px solid rgba(244, 244, 244, 0.4);
  animation-duration: 3.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: questionRotate;
  animation-direction: alternate;

  @media screen and (min-width: 768px) {
    max-width: 360px;
  }

  &__content {
    @apply pb-144;
    border-radius: inherit;
  }

  &__answers {
    @apply flex justify-between items-center mb-20;

    &-text {
      @apply block w-200 sm:w-300 my-0 mx-auto text-16 sm:text-24 leading-34 font-navigo-regular;
    }

    &-item {
      @apply w-84 py-20 text-18 leading-22 uppercase font-navigo-black;

      &--yes {
        @apply text-emerald;
        background-color: rgba(88, 197, 133, 0.15);
        border-radius: 2px 18px 2px 2px;
      }

      &--no {
        @apply text-cardinal;
        background: rgba(204, 31, 68, 0.15);
        border-radius: 18px 2px 2px 2px;
      }
    }
  }
}

.lights {
  @apply absolute bottom-0;
  left: 50%;
  z-index: -1;
  width: 100%;
  transform: translateX(-50%);

  &::after,
  &::before {
    @apply inline-block w-160 sm:w-200 h-200;
    content: '';
    filter: blur(60px);
  }

  &::before {
    @apply opacity-0;
    background: linear-gradient(90.62deg, #cc1f44 9.49%, rgba(204, 31, 68, 0) 89.86%);
    transform: rotate(-6.5deg);
    animation-duration: 3.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: lightsNoFade;
    animation-direction: alternate;
  }

  &::after {
    background: linear-gradient(90.27deg, rgba(88, 197, 133, 0) -8.06%, #58c585 99.3%);
    transform: rotate(6.5deg);
    animation-duration: 3.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: lightsYesFade;
    animation-direction: alternate;
  }
}

.landing-top-prizes {
  @apply w-full sm:w-312 mt-48 sm:mt-0 flex flex-col;
  box-sizing: content-box;
}

.table-container {
  @apply flex-auto;
}

.footer-container {
  background: rgba(0, 0, 0, 0.4);
}

.game {
  width: 100%;
  background-image: url('/images/game-screen-bg.png');
  background-size: 1280px;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-attention {
  @apply justify-center items-center;
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #101010;
  background-image: url('/images/1280_star.png');
  background-size: contain;
  background-position: left;
  background-repeat: repeat;
  z-index: 9999;
  & svg {
    @apply mx-auto;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .landing-attention {
    display: flex;
  }
}

.landing-players-top-block {
  @apply rounded-24 bg-no-repeat relative z-10;
  background-color: #141416;
  padding: 32px 10px;
  background-image: url('/images/circles.svg'), url('/images/circles-2.svg');
  background-position: top, bottom right;
}

@screen sm {
  .landing-players-top-block {
    @apply rounded-24;
    padding: 53px 106px 66px 96px;
  }
}

@keyframes questionRotate {
  from {
    transform: translateX(-50%) rotate(10deg);
  }

  to {
    transform: translateX(-50%) rotate(-10deg);
  }
}

@keyframes lightsNoFade {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 0.6;
    transform: translateY(0px);
  }
}
@keyframes lightsYesFade {
  from {
    opacity: 0.6;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>
