<style lang='scss'>
</style>

<template>
  <div class="mailer__body">
    <div class="mailer__title">
      <div class="mailer__title-part">
        <IconMailerIncoming />
      </div>
      <IconMailerCount />
    </div>

    <div class="mailer__messages">
      <ul class="mailer__list">
        <li class="mailer__item" v-for="message in messages" :key="message.key">
          <div class="mailer__item-avatar"></div>
          <div class="mailer__item-message">
            <div class="mailer__item-message__title"></div>
            <div class="mailer__item-message__subtitle"></div>
            <div class="mailer__item-message__text"></div>
          </div>
          <div class="mailer__item-author"></div>
        </li>
      </ul>
    </div>
    <div class="mailer__modal" ref="mailer">
      <div class="mailer__modal-container">
        <div class="mailer__modal-header">
          <div class="mailer__modal-header__icon">
            <IconMailerUnread />
          </div>
          <div class="mailer__modal-header__title">Новое сообщение</div>
        </div>

        <div class="mailer__modal-body">
          <div class="mailer__modal-body__author">
            <span class="mailer__modal-body__from">От:</span>
            <span class="mailer__modal-body__name"> {{ author }} </span>
          </div>
          <div class="mailer__modal-body__message">{{ message }}</div>
        </div>
      </div>

      <GeeckoButton
        icon="true"
        text="Играть"
        @click="play"
        :disabled="loading"
        class="mailer__modal-link"
      />
    </div>
  </div>
</template>

<script>
import IconMailerIncoming from '../Icons/IconMailerIncoming.vue';
import IconMailerCount from '../Icons/IconMailerCount.vue';
import IconMailerUnread from '../Icons/IconMailerUnread.vue';
import GeeckoButton from '../UI/GeeckoButton.vue';

const TWEEN = require('tween.js');

const MESSAGE_FADE_OUT = 3000;

export default {
  name: 'MailerLandingContent',
  components: {
    IconMailerIncoming,
    IconMailerCount,
    IconMailerUnread,
    GeeckoButton,
  },
  data() {
    return {
      loading: false,
      author: 'Raifworker1995',
      message:
        'Мы подготовили испытание для крутых Mobile-разработчиков. Примешь наш вызов?',
      messages: {
        // TODO: заглушка
        1: {
          key: 1,
        },
        2: {
          key: 2,
        },
        3: {
          key: 3,
        },
        4: {
          key: 4,
        },
        5: {
          key: 5,
        },
        6: {
          key: 6,
        },
        7: {
          key: 7,
        },
      },
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    play() {
      this.loading = true;
      this.$emit('start');
      this.startMatrix = true;
      const opacityTweenFrom = { opacity: 1 };
      new TWEEN.Tween(opacityTweenFrom)
        .to(
          {
            opacity: 0,
          },
          MESSAGE_FADE_OUT,
        )
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          if (this.$refs.mailer) {
            this.$refs.mailer.style.opacity = `${opacityTweenFrom.opacity}`;
          }
        })
        .onComplete(() => {})
        .start();
    },
  },
  computed: {},
};
</script>
