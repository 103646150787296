import Vue from 'vue';
import axios from 'axios';

const defaultState = {
  authToken: null,
  authResult: '',
  user: {},
};

const mutations = {
  SET_TOKEN: (state, payload) => {
    state.authToken = payload;
  },
  SET_USER: (state, payload) => {
    Vue.set(state, 'user', payload);
  },
  AUTH_FAILURE: (state) => {
    state.authResult = 'failure';
  },
  AUTH_SUCCESS: (state) => {
    state.authResult = 'success';
  },
};

const actions = {
  setToken({ commit }, payload) {
    commit('SET_TOKEN', payload);
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },
  async fetchUser({ commit/* , dispatch */ }) {
    console.log('[vuex-user] fetchUser start');
    try {
      const { data } = await axios.get('/users/current');
      commit('AUTH_SUCCESS');
      commit('SET_USER', data.data);
      console.log('[vuex-user] fetchUser done', data);
    } catch (e) {
      console.log('[vuex-user] fetchUser error', e);
      // await dispatch('logout');
    }
  },
  async updateUser({ commit, state }, data) {
    const resp = await axios.patch(`/users/${state.user.id}`, data);
    commit('SET_USER', resp.data.data);
    return resp.data;
  },
  async updateLanguage({ commit, state }, data) {
    const resp = await axios.patch('/users/current/change-primary-language', data);
    commit('SET_USER', {
      ...state.user,
      primary_programming_language: resp.data.language,
    });
    return resp;
  },
  async logout({ commit, state }) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_SERVER_URL,
      headers: { Authorization: `Bearer ${state.authToken}` },
    });
    try {
      console.log('[vuex-user] logout start');
      await instance.post('/logout');
    } catch (e) {
      console.log('[vuex-user] logout error', e);
    } finally {
      commit('SET_USER', {});
      commit('SET_TOKEN', null);
      localStorage.clear();
      console.log('[vuex-user] logout done');
    }
  },
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.authToken,
  isAuthenticated: (state) => state.authToken && state.user && state.user.id > 0,
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
