<template>
  <div class="glitch-container">
    <template v-if="!isBroken">
      <component :is="iconName" class="glitch top" />
      <component :is="iconName" class="glitch bottom" />
    </template>
    <component :is="`${iconName}Broken`" class="broken" :class="{ visible: isBroken }" />
  </div>
</template>

<script>
import IconMailerLogoTitle from '../Icons/IconMailerLogoTitle.vue';
import IconMailerLogoTitleBroken from '../Icons/IconMailerLogoTitleBroken.vue';
import IconMailerLogoMailBroken from '../Icons/IconMailerLogoMailBroken.vue';
import IconMailerLogoMail from '../Icons/IconMailerLogoMail.vue';
import IconMailerClose from '../Icons/IconMailerClose.vue';
import IconMailerCloseBroken from '../Icons/IconMailerCloseBroken.vue';
import IconWindowUiLogo from '../Icons/IconWindowUiLogo.vue';
import IconWindowUiFile from '../Icons/IconWindowUiFile.vue';
import IconWindowUiMail from '../Icons/IconWindowUiMail.vue';
import IconWindowUiMailBroken from '../Icons/IconWindowUiMailBroken.vue';
import IconWindowUiOff from '../Icons/IconWindowUiOff.vue';
import IconWindowUiOptions from '../Icons/IconWindowUiOptions.vue';
import IconWindowUiPc from '../Icons/IconWindowUiPc.vue';
import IconWindowUiPcBroken from '../Icons/IconWindowUiPcBroken.vue';
import IconWindowUiRef from '../Icons/IconWindowUiRef.vue';
import IconWindowUiReport from '../Icons/IconWindowUiReport.vue';
import IconWindowUiReportBroken from '../Icons/IconWindowUiReportBroken.vue';
import IconWindowUiTrash from '../Icons/IconWindowUiTrash.vue';
import IconWindowUiTrashBroken from '../Icons/IconWindowUiTrashBroken.vue';
import IconWindowUiWindows from '../Icons/IconWindowUiWindows.vue';
import IconWindowUiLogoBroken from '../Icons/IconWindowUiLogoBroken.vue';
import IconWindowUiFileBroken from '../Icons/IconWindowUiFileBroken.vue';
import IconWindowUiWindowsBroken from '../Icons/IconWindowUiWindowsBroken.vue';
import IconWindowUiOptionsBroken from '../Icons/IconWindowUiOptionsBroken.vue';
import IconWindowUiRefBroken from '../Icons/IconWindowUiRefBroken.vue';
import IconWindowUiOffBroken from '../Icons/IconWindowUiOffBroken.vue';

export default {
  name: 'GlitchItem',
  components: {
    IconMailerLogoTitle,
    IconMailerLogoTitleBroken,
    IconMailerLogoMail,
    IconMailerLogoMailBroken,
    IconMailerClose,
    IconMailerCloseBroken,
    IconWindowUiLogo,
    IconWindowUiFile,
    IconWindowUiMail,
    IconWindowUiOff,
    IconWindowUiOptions,
    IconWindowUiPc,
    IconWindowUiRef,
    IconWindowUiReport,
    IconWindowUiTrash,
    IconWindowUiWindows,
    IconWindowUiPcBroken,
    IconWindowUiTrashBroken,
    IconWindowUiMailBroken,
    IconWindowUiReportBroken,
    IconWindowUiLogoBroken,
    IconWindowUiFileBroken,
    IconWindowUiWindowsBroken,
    IconWindowUiOptionsBroken,
    IconWindowUiRefBroken,
    IconWindowUiOffBroken,
  },
  data() {
    return {};
  },
  props: {
    iconName: { type: String, require: true },
    isBroken: { type: Boolean, default: false },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
