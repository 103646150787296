<template>
  <div class="dialog" :class="className">
    <div class="dialog-npc" v-if="withNpc"></div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  data() {
    return {};
  },
  props: {
    withNpc: { type: Boolean, default: false },
    className: { type: String },
  },
};
</script>

<style lang="scss">
$border: 1px;

.dialog {
  width: 100%;
  min-height: 286px;
  &.bordered {
    border: 1px solid rgba(244, 244, 244, 0.4);
  }
  &.green-blue {
    background: linear-gradient(35.5deg, #1A213A -3.15%, #0D2E20 83.76%);
    &::before {
    background: linear-gradient(to right, #475DEB, #00A460);
    }
  }
  &.blue {
    background: linear-gradient(0deg, rgba(36, 101, 195, 0.05),
      rgba(36, 101, 195, 0.05)), #101010;
    border: 0.96309px solid #2465C3;
    &::before {
      background: none;
    }
  }
  @apply flex items-center relative text-white px-16 pb-64 pt-72;
  border-radius: 4px;

  background: rgba(20, 20, 20, 0.88);

  &-npc {
    @apply absolute bg-contain bg-no-repeat bg-center;
    width: 55px;
    height: 71px;
    background-image: url('/images/hacker.png');
    left: calc(50% - 26px);
    bottom: 100%;
  }
}
</style>
