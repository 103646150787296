<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00004 3H5.33337V4.33333H4.00004V3ZM4 5H5.33333V6.33333H4V5ZM5.33333 12H4V13.3333H5.33333V12ZM12 11H13.3333V12.3333H12V11ZM7.33333 6H6V7.33333H7.33333V6ZM7.33344 7.33333H8.66677V8.66667H7.33344V7.33333ZM6.33331 10L7.33344 10V8.66667H6.0001V10L4.99998 10V11.3333H6.33331V10ZM8.66677 8.66667H10.0001V10L11.3333 10V11.3333H10V10L8.66677 10V8.66667ZM8.66677 7.33333H10.0001V6H8.66677V7.33333ZM11 6H12.3333V7.33333H11V6ZM12.3333 3H11V4.33333H12.3333V3Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerCloseBroken',
};
</script>
