<!-- eslint-disable max-len -->
<template>
  <svg width="34" height="9" viewBox="0 0 34 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.92657 0H6.24657V1.44H1.92657V0ZM0.486572 1.44H1.92657V7.2H0.486572V1.44ZM6.24657 1.44H7.68657V7.2H6.24657V1.44ZM1.92657 7.2H6.24657V8.64H1.92657V7.2Z" fill="currentColor"/>
    <path d="M13.5633 4.32H10.6833V8.64H9.24329V0H10.6833V2.88H13.5633V4.32ZM16.4433 1.44H15.0033V0H16.4433V1.44ZM15.0033 2.88H13.5633V1.44H15.0033V2.88ZM13.5633 5.76V4.32H15.0033V5.76H13.5633ZM15.0033 5.76H16.4433V8.64H15.0033V5.76Z" fill="currentColor"/>
    <path d="M18 0H19.44V2.88H23.76V0H25.2V8.64H23.76V4.32H19.44V8.64H18V0Z" fill="currentColor"/>
    <path d="M28.1967 0H32.5167V1.44H28.1967V0ZM26.7567 1.44H28.1967V5.76H32.5167V1.44H33.9567V8.64H32.5167V7.2H28.1967V8.64H26.7567V1.44Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiWindows',
};
</script>
