<!-- eslint-disable max-len -->
<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="3.00012" width="9" height="1.5" fill="white"/>
    <rect x="1.5" y="13.5" width="15" height="1.5" fill="white"/>
    <rect x="1.5" y="3.00012" width="1.5" height="12" fill="white"/>
    <rect x="15" y="7.50012" width="1.5" height="7.5" fill="white"/>
    <rect x="4.5" y="6.00024" width="1.5" height="1.5" fill="white"/>
    <rect x="6" y="7.50012" width="1.5" height="1.5" fill="white"/>
    <rect width="1.5" height="1.5" transform="matrix(-1 0 0 1 12 7.50012)" fill="white"/>
    <rect x="7.5" y="9" width="3" height="1.5" fill="white"/>
    <rect x="12" y="1.50024" width="4.5" height="4.5" fill="#FEE600"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMailerUnread',
};
</script>
