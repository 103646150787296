<!-- eslint-disable max-len -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.441406 7.07551L19.6436 1.48291L23.5584 14.9244L4.35623 20.517L0.441406 7.07551ZM2.85097 8.19644L5.78709 18.2776L21.1488 13.8035L18.2127 3.72236L2.85097 8.19644ZM6 7.99995H8V9.99995H6V7.99995ZM8 9.99995L10 9.99995V12H8V9.99995ZM18 7.99995H16V9.99995H18V7.99995ZM14 12H10V14H14V12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerLogoMailBroken',
};
</script>
