<!-- eslint-disable max-len -->
<template>
  <svg width="78" height="12" viewBox="0 0 78 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.594727 2.892H2.03473V5.772H6.35473V2.892H7.79473V11.532H6.35473V7.212H2.03473V11.532H0.594727V2.892Z"
      fill="currentColor"
    />
    <path
      d="M10.7914 2.892H15.1114V4.332H10.7914V2.892ZM9.35145 4.332H10.7914V8.652H15.1114V4.332H16.5514V11.532H15.1114V10.092H10.7914V11.532H9.35145V4.332Z"
      fill="currentColor"
    />
    <path
      d="M19.5482 2.892H23.8682V4.332H19.5482V2.892ZM18.1082 4.332H19.5482V10.092H18.1082V4.332ZM23.8682 4.332H25.3082V5.772H23.8682V4.332ZM23.8682 8.652H25.3082V10.092H23.8682V8.652ZM19.5482 10.092H23.8682V11.532H19.5482V10.092Z"
      fill="currentColor"
    />
    <path
      d="M26.8649 2.892H34.0649V4.332H31.1849V11.532H29.7449V4.332H26.8649V2.892Z"
      fill="currentColor"
    />
    <path
      d="M35.6216 2.892H41.3816V4.332H37.0616V8.652H41.3816V10.092H37.0616V11.532H35.6216V2.892ZM41.3816 4.332H42.8216V8.652H41.3816V4.332Z"
      fill="currentColor"
    />
    <path
      d="M45.8183 2.892H50.1383V4.332H45.8183V2.892ZM44.3783 4.332H45.8183V10.092H44.3783V4.332ZM50.1383 4.332H51.5783V10.092H50.1383V4.332ZM45.8183 10.092H50.1383V11.532H45.8183V10.092Z"
      fill="currentColor"
    />
    <path
      d="M54.575 0H58.895V1.452H54.575V0ZM56.015 8.652V10.092H54.575V11.532H53.135V2.892H54.575V8.652H56.015ZM60.335 2.892V11.532H58.895V7.212H57.455V5.772H58.895V2.892H60.335ZM56.015 7.212H57.455V8.652H56.015V7.212Z"
      fill="currentColor"
    />
    <path
      d="M66.2118 7.212H63.3318V11.532H61.8918V2.892H63.3318V5.772H66.2118V7.212ZM69.0918 4.332H67.6518V2.892H69.0918V4.332ZM67.6518 5.772H66.2118V4.332H67.6518V5.772ZM66.2118 8.652V7.212H67.6518V8.652H66.2118ZM67.6518 8.652H69.0918V11.532H67.6518V8.652Z"
      fill="currentColor"
    />
    <path
      d="M73.5285 8.652V10.092H72.0885V11.532H70.6485V2.892H72.0885V8.652H73.5285ZM77.8485 2.892V11.532H76.4085V7.212H74.9685V5.772H76.4085V2.892H77.8485ZM73.5285 7.212H74.9685V8.652H73.5285V7.212Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiOptions',
};
</script>
