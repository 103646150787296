<template>
  <button @click="$emit('click')" class="geecko-button" :class="className">
    <div class="geecko-button__icon" v-if="!!icon">
      <IconGeeckoSquare />
    </div>
    <span class="geecko-button__text"> {{ text }} </span>
  </button>
</template>
<script>
import IconGeeckoSquare from '../Icons/IconGeeckoSquare.vue';

export default {
  name: 'GeeckoButton',
  components: { IconGeeckoSquare },
  props: {
    icon: String,
    text: String,
    className: String,
  },
};
</script>
<style scoped lang="scss">
.geecko-button {
  @apply flex items-center justify-center border-0 bg-primary rounded-2;
  width: 100%;
  transition: 0.25s;

  &:hover {
    @apply bg-golden-fizz;
  }

  &:active {
    @apply bg-sahara;
  }

  &:disabled {
    @apply cursor-not-allowed;
    color: rgba(1, 1, 1, 0.4);
    background: #646879;
  }

  &__icon {
    margin-right: 6px;
  }

  &__text {
    @apply font-navigo-black text-12 leading-48 text-shark uppercase;
  }
}
</style>
