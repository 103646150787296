<!-- eslint-disable max-len -->
<template>
  <svg width="92" height="75" viewBox="0 0 92 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0605 61.64H16.6205V67.88H15.0605V61.64ZM15.0605 69.44H16.6205V71H15.0605V69.44ZM18.3136 69.44H26.1136V71H18.3136V69.44ZM30.92 63.2H29.36V61.64H30.92V63.2ZM27.8 69.44V63.2H29.36V69.44H27.8ZM29.36 71V69.44H30.92V71H29.36ZM34.1746 61.64V63.2H32.6146V61.64H34.1746ZM35.7346 63.2V69.44H34.1746V63.2H35.7346ZM32.6146 69.44H34.1746V71H32.6146V69.44ZM38.9891 64.76H40.5491V61.64H42.1091V64.76H43.6691V66.32H42.1091V69.44H40.5491V66.32H38.9891V64.76ZM37.4291 63.2H38.9891V64.76H37.4291V63.2ZM43.6691 63.2H45.2291V64.76H43.6691V63.2ZM37.4291 66.32H38.9891V67.88H37.4291V66.32ZM43.6691 66.32H45.2291V67.88H43.6691V66.32ZM51.5956 69.44H53.1556V71H48.4756V69.44H50.0356V67.88H48.4756V66.32H50.0356V64.76H48.4756V63.2H50.0356V61.64H51.5956V63.2H53.1556V64.76H51.5956V66.32H53.1556V67.88H51.5956V69.44ZM48.4756 64.76V66.32H46.9156V64.76H48.4756ZM54.7156 67.88V69.44H53.1556V67.88H54.7156ZM57.962 64.76H56.402V63.2H57.962V64.76ZM64.202 64.76H62.642V63.2H64.202V64.76ZM62.642 66.32H61.082V64.76H62.642V66.32ZM61.082 67.88H59.522V66.32H61.082V67.88ZM59.522 69.44H57.962V67.88H59.522V69.44ZM57.962 71H56.402V69.44H57.962V71ZM64.202 71H62.642V69.44H64.202V71ZM67.4485 61.64V63.2H65.8885V61.64H67.4485ZM69.0085 63.2V69.44H67.4485V63.2H69.0085ZM65.8885 69.44H67.4485V71H65.8885V69.44ZM72.2631 64.76V63.2H75.3831V64.76H72.2631ZM72.2631 64.76V66.32H70.7031V64.76H72.2631ZM72.2631 67.88V66.32H75.3831V67.88H72.2631ZM78.5031 67.88H76.9431V66.32H78.5031V67.88ZM72.2631 67.88V69.44H70.7031V67.88H72.2631ZM76.9431 67.88V69.44H75.3831V67.88H76.9431ZM75.3831 69.44V71H72.2631V69.44H75.3831ZM76.9431 71V69.44H78.5031V71H76.9431Z"
      fill="currentColor"
    />
    <g clip-path="url(#report-br)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.4087 10.4527L32.4776 11.1916L33.2165 15.1228L37.1476 14.3839L36.4087 10.4527ZM48.2022 8.23606L52.1334 7.49716L52.8723 11.4283L48.9411 12.1672L48.2022 8.23606ZM25.3541 16.6006L29.2853 15.8617L30.7631 23.724L58.2812 18.5516L56.8034 10.6894L60.7346 9.95053L62.2124 17.8127L62.9513 21.7439L31.502 27.6551L35.9354 51.2421L32.0043 51.981L27.5708 28.394L26.8319 24.4629L25.3541 16.6006ZM62.7147 42.1387L36.078 47.1453L36.8169 51.0765L63.4536 46.0698L63.1279 44.3371L67.059 43.5982L63.8849 26.711L59.9537 27.4499L62.7147 42.1387Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="report-br">
        <rect
          width="48"
          height="48"
          fill="currentColor"
          transform="translate(17.9796 9.84668) rotate(-10.6452)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiReportBroken',
};
</script>
