<template>
  <Dialog class="bordered" :class="{'conversation__dialog': isManager}">
    <div class="text-center mx-auto">
      <h3 class="font-navigo-medium text-18 leading-28 uppercase">Уровень пройден</h3>
      <div class="result__container">
        <div class="result__right">
          <img :src="$options.check" />
          <div class="result__right-text">
            {{ rightAnswers }}
          </div>
        </div>
        <div class="result__wrong">
          <img :src="$options.cross" />
          <div class="result__wrong-text">
            {{ wrongAnswers }}
          </div>
        </div>
      </div>
      <p class="mt-16 text-16 leading-24 font-navigo-thin">
        {{ resultText }}
      </p>
    </div>
  </Dialog>
</template>

<script>
import check from '@/assets/images/check.svg';
import cross from '@/assets/images/cross.svg';
import Dialog from './Dialog.vue';

export default {
  components: { Dialog },
  name: 'QuizResults',
  check,
  cross,
  data() {
    return {};
  },
  props: {
    rightAnswers: { type: Number },
    wrongAnswers: { type: Number },
    resultText: { type: String },
    isManager: Boolean,
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang='scss'>
.result {
  @apply text-16 leading-26 font-navigo-medium text-center mx-auto;
  & svg {
    @apply mx-auto;
  }

  &__container {
    @apply flex items-center justify-center mt-8 font-navigo-medium;
  }

  &__right {
    @apply flex;
  }

  &__right-text {
    @apply text-16 leading-24 text-green ml-4;
  }

  &__wrong {
    @apply flex ml-16;
  }

  &__wrong-text {
    @apply text-16 leading-24 text-red ml-4;
  }
}

</style>
