<!-- eslint-disable max-len -->
<template>
  <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.29167 0.208252H0.375V14.7916H3.29167V17.7083H6.20833V20.6249H20.7917V17.7083H23.7083V14.7916H26.625V0.208252H23.7083V14.7916H20.7917V17.7083H6.20833L6.20833 14.7916H3.29167V8.26952L14.6835 14.7339L16.123 12.1972L3.43945 4.99984L3.29167 5.26027V0.208252ZM15 11.5535L16.4093 8.99996L18.9629 10.4093L17.5536 12.9628L15 11.5535ZM23.577 6.92923L22.9292 4L20 4.64777L20.6477 7.577L23.577 6.92923Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiOffBroken',
};
</script>
