<template>
  <div ref="outer" class="end-of-game">
    <div class="flex-1"></div>
    <Card v-if="showCard" ref="mainCard" no-border>
      <Dialog class="blue bordered">
        <div class="text-center mx-auto">
          <h3 class="text-18 leading-28 mb-16 uppercase font-navigo-medium">
            Решил попробовать еще?
          </h3>
          <p class="text-16 leading-24 font-navigo-thin">
            Повторное прохождение не учитывается в списке лидеров
          </p>
        </div>
      </Dialog>
    </Card>
    <div class="flex-1"></div>
    <div class="end-of-game__buttons">
      <Button
        @click="$emit('restart')"
        class="mt-12"
        is-primary is-uppercase
      >
        Пройти заново
      </Button>
      <Button
        @click="goToMain"
        class="mt-12"
        is-uppercase
        is-gray
        is-has-bg
        :style="{textAlign: 'center', padding: 0}"
      >
        Вернуться на главную
      </Button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

import Button from '@/components/UI/Button.vue';
import Dialog from '@/components/Dialog.vue';
import Card from '@/components/Card.vue';

export default {
  data() {
    return {
      showCard: false,
      showButtons: false,
      showOuter: false,
    };
  },
  components: {
    Dialog,
    Button,
    Card,
  },
  methods: {
    show() {
      this.showOuter = true;
      this.showCard = true;
      this.$nextTick(() => {
        gsap.to(this.$refs.outer, {
          duration: 1,
          autoAlpha: 1,
        });
        this.$refs.mainCard.appear(() => {
          setTimeout(() => {
            this.$refs.mainCard.flip();
            this.showButtons = true;
            this.$nextTick(() => {
              gsap.to(this.$refs.buttons, {
                duration: 1,
                autoAlpha: 1,
              });
            });
          }, 250);
        });
      });
    },
    goToMain() {
      this.$router.push({ name: 'LandingPage' });
    },
  },
};
</script>

<style lang="scss">
.end-of-game {
  @apply flex flex-col items-center justify-between h-full relative z-0 pt-40;

  &__dialog {
    @apply flex flex-col items-center text-center;
  }

  &__image {
    @apply w-48 h-48;
  }

  &__title {
    @apply text-base leading-28 mt-4 font-navigo-medium uppercase;
  }

  &__text {
    @apply mt-16 text-16 leading-24 font-navigo-regular;
  }

  &__buttons {
    @apply mt-48 flex flex-col w-full;
  }
}
</style>
