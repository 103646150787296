import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import InputMask from 'vue-input-mask';
import VueGtm from '@gtm-support/vue2-gtm';
import VModal from 'vue-js-modal';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/fonts.scss';
import './assets/tailwind.css';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VModal);
Vue.component('InputMask', InputMask);

// Axios
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_URL}/api`;
axios.defaults.withCredentials = true;

// Auth
const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token');

if (uriParams.get('token')) {
  window.location.href = window.location.origin + window.location.pathname;
}

// GTM
const gtmToken = process.env.VUE_APP_GTM;
if (gtmToken) {
  Vue.use(VueGtm, {
    // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview:
    // 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234',
    // gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of
    // container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    id: process.env.VUE_APP_GTM,

    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID
    //   // (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x',
    // },
    // Script can be set to `defer` to speed up page load at the cost of less accurate results
    // (in case visitor leaves before script is loaded, which is unlikely but possible).
    // Defaults to false, so the script is loaded `async` by default
    defer: false,

    // Will add `async` and `defer` to the script tag to not block requests for old browsers
    // that do not support `async`
    compatibility: false,

    // Will add `nonce` to the script tag
    // nonce: '2726c7f26c',

    // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled:
    // !!GDPR_Cookie (optional)
    enabled: true,

    // Whether or not display console logs debugs (optional)
    debug: true,

    // Whether or not to load the GTM Script (Helpful if you are including GTM manually,
    // but need the dataLayer functionality in your components) (optional)
    loadScript: true,

    // Pass the router instance to automatically sync with router (optional)
    vueRouter: router,

    // Don't trigger events for specified router names (optional)
    // ignoredViews: ['homepage'],
    // Whether or not call trackView in Vue.nextTick
    trackOnNextTick: false,
  });
}

if (authToken) {
  store.commit('user/SET_TOKEN', authToken);
}

if (store.state.user.authToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${store.state.user.authToken}`;
}
store.dispatch('user/fetchUser').then(() => {
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
});
