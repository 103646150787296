import axios from 'axios';

const defaultState = {
  players: false,
  loading: false,
  error: false,
  leaderboard: {
    ios: [],
    android: [],
    date: null,
  },
};

const mutations = {
  SET_PLAYERS_START: (state) => {
    state.loading = true;
  },
  SET_PLAYERS_SUCCESS: (state, payload) => {
    state.loading = false;
    state.players = payload;
  },
  SET_LEADERBOARD_SUCCESS: (state, payload) => {
    state.loading = false;
    state.leaderboard = payload;
  },
  SET_PLAYERS_ERROR: (state, payload) => {
    state.error = payload;
    state.loading = false;
  },
};

const actions = {
  async playersFetch({ commit }) {
    commit('SET_PLAYERS_START');
    try {
      const response = await axios.get('/scoreboard?limit=6');
      commit('SET_PLAYERS_SUCCESS', response.data.collection);
    } catch (e) {
      commit('SET_PLAYERS_ERROR', e);
    }
  },
  async fetchLeaderboard({ commit }) {
    commit('SET_PLAYERS_START');
    try {
      const response = await axios.get('/leaderboard');
      commit('SET_LEADERBOARD_SUCCESS', response.data.data);
    } catch (e) {
      commit('SET_PLAYERS_ERROR', e);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
