<!-- eslint-disable max-len -->
<template>
<svg width="144" height="41" viewBox="0 0 144 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.025 32.4603C40.025 37.3233 37.3233 40.025 32.4603 40.025H7.56473C2.70169 40.025 0 37.3233 0 32.4603V7.56473C0 2.70169 2.70169 0 7.56473 0H32.4703C37.3233 0 40.025 2.70169 40.025 7.56473V32.4603Z" fill="#FEE600"/>
<path d="M25.7361 18.0612V14.2388L27.117 12.8579V16.17L28.3177 17.3708L33.1508 12.5377L34.3415 13.7285C34.3315 12.8279 34.1514 10.2163 31.94 8.04493C29.5585 5.74349 26.9769 6.78414 26.0963 7.6647L21.2733 12.4877L22.5041 13.7185L20.0125 16.23L17.521 13.7385L18.7518 12.5077L13.9287 7.68471C13.0482 6.80416 10.4666 5.76351 8.08509 8.06495C5.87371 10.2363 5.6936 12.8479 5.68359 13.7485L6.87434 12.5578L11.7074 17.3908L12.9081 16.19V12.878L14.289 14.2588V18.0812L16.2302 20.0124L6.66421 29.5784L10.4466 33.3507L20.0125 23.7948L29.5785 33.3607L33.3609 29.5884L23.7949 20.0124L25.7361 18.0612Z" fill="#2B2D33"/>
<path d="M46.6997 21.6833H53.9142V23.9548H49.4014V26.2862H50.9023C51.913 26.2862 53.2138 26.4063 54.1944 27.3669C54.6147 27.7872 55.135 28.5576 55.135 29.7984C55.135 30.799 54.8048 31.6896 54.1944 32.3C53.3339 33.1605 52.0931 33.3506 50.6722 33.3506H46.6997V21.6833ZM50.4621 31.1793C50.8323 31.1793 51.4927 31.1493 51.913 30.8091C52.0531 30.689 52.3632 30.3688 52.3632 29.7784C52.3632 29.2181 52.0831 28.8678 51.8429 28.6977C51.4227 28.3975 50.7422 28.3875 50.4621 28.3875H49.3914V31.1893H50.4621V31.1793Z" fill="white"/>
<path d="M62.3994 25.5453H64.9209V33.3502H62.3994V32.4096C61.7189 33.4603 60.7383 33.6204 60.0879 33.6204C58.9672 33.6204 58.0266 33.3402 57.1661 32.4296C56.3456 31.5591 56.0254 30.5384 56.0254 29.5078C56.0254 28.197 56.5157 27.0763 57.2361 26.3558C57.9166 25.6754 58.8672 25.2852 59.9478 25.2852C60.6083 25.2852 61.6789 25.4453 62.3994 26.4259V25.5453ZM59.1974 28.0169C58.9372 28.277 58.617 28.7373 58.617 29.4378C58.617 30.1382 58.9172 30.5885 59.1373 30.8386C59.4876 31.2089 60.0279 31.449 60.6283 31.449C61.1386 31.449 61.6089 31.2389 61.9391 30.9087C62.2693 30.5985 62.5495 30.1082 62.5495 29.4378C62.5495 28.8774 62.3393 28.3571 61.9691 28.0069C61.5889 27.6366 61.0285 27.4665 60.5682 27.4665C60.0279 27.4565 59.5276 27.6867 59.1974 28.0169Z" fill="white"/>
<path d="M66.4424 25.5454H68.964V28.2571H71.7857V25.5454H74.3073V33.3503H71.7857V30.2884H68.964V33.3503H66.4424V25.5454Z" fill="white"/>
<path d="M75.8179 25.5454H78.3395V28.6473L80.891 25.5454H84.023L80.6809 29.1677L84.2531 33.3503H81.0011L78.3395 29.8881V33.3503H75.8179V25.5454Z" fill="white"/>
<path d="M50.8115 6.67358C51.8222 6.67358 52.9429 6.81367 53.8735 7.63418C54.8741 8.50473 55.0142 9.66545 55.0142 10.466C55.0142 11.9369 54.4538 12.7574 54.0135 13.2177C53.083 14.1583 51.8622 14.2483 51.0417 14.2483H49.4007V18.3409H46.709V6.67358H50.8115ZM49.3907 12.0469H50.3513C50.7215 12.0469 51.3619 12.0269 51.8022 11.6067C52.0423 11.3665 52.2424 10.9563 52.2424 10.4559C52.2424 9.96564 52.0723 9.56539 51.8022 9.31523C51.4019 8.935 50.8216 8.87496 50.3012 8.87496H49.3907V12.0469Z" fill="white"/>
<path d="M61.5888 10.5363H64.1104V18.3412H61.5888V17.4006C60.9084 18.4512 59.9278 18.6113 59.2774 18.6113C58.1567 18.6113 57.2161 18.3312 56.3556 17.4206C55.535 16.55 55.2148 15.5294 55.2148 14.4988C55.2148 13.1879 55.7051 12.0672 56.4256 11.3468C57.106 10.6664 58.0566 10.2761 59.1373 10.2761C59.7977 10.2761 60.8684 10.4362 61.5888 11.4168V10.5363ZM58.3868 13.0078C58.1267 13.268 57.8065 13.7283 57.8065 14.4287C57.8065 15.1292 58.1066 15.5794 58.3268 15.8296C58.677 16.1998 59.2173 16.44 59.8177 16.44C60.328 16.44 60.7983 16.2298 61.1285 15.8996C61.4587 15.5894 61.7389 15.0991 61.7389 14.4287C61.7389 13.8684 61.5288 13.348 61.1586 12.9978C60.7783 12.6276 60.218 12.4575 59.7577 12.4575C59.2273 12.4475 58.717 12.6776 58.3868 13.0078Z" fill="white"/>
<path d="M68.1524 10.536V14.9988L71.4745 10.536H73.816V18.3409H71.2944V13.8081L67.9723 18.3409H65.6309V10.536H68.1524ZM72.8954 7.38404C72.6853 8.01443 72.265 8.55477 71.7747 8.87497C71.1143 9.31525 70.3038 9.36528 69.7234 9.36528C69.1431 9.36528 68.3426 9.31525 67.6721 8.87497C67.1818 8.56478 66.7616 8.01443 66.5514 7.38404L68.3025 6.70361C68.3726 7.05383 68.5427 7.33401 68.7528 7.50411C68.983 7.69423 69.2731 7.81431 69.7134 7.81431C70.1537 7.81431 70.4439 7.69423 70.674 7.50411C70.8841 7.33401 71.0542 7.05383 71.1243 6.70361L72.8954 7.38404Z" fill="white"/>
<path d="M79.3891 22.2331V18.4607H79.289C77.5179 18.4607 76.5373 17.7302 76.087 17.29C75.5267 16.7496 74.9663 15.8191 74.9663 14.3982C74.9663 12.8572 75.6667 11.8766 76.3172 11.3563C77.0876 10.7459 78.2384 10.4157 79.279 10.4157H79.3991V6.67334H81.9207V10.4157H82.0407C83.0714 10.4157 84.2321 10.7459 85.0026 11.3563C85.653 11.8766 86.3534 12.8572 86.3534 14.3982C86.3534 15.8191 85.7931 16.7396 85.2327 17.29C84.7824 17.7302 83.8018 18.4607 82.0307 18.4607H81.9307V22.2331H79.3891ZM79.5091 12.4469C78.9188 12.4169 78.2984 12.6171 77.9382 12.9873C77.638 13.2875 77.4278 13.7878 77.4278 14.4082C77.4278 15.0186 77.638 15.5289 77.9382 15.8391C78.2684 16.2093 78.8687 16.4494 79.5091 16.4294V12.4469ZM81.7906 16.4394C82.441 16.4595 83.0313 16.2093 83.3616 15.8491C83.6617 15.5389 83.8719 15.0286 83.8719 14.4182C83.8719 13.8078 83.6617 13.2975 83.3616 12.9973C82.9913 12.6271 82.3809 12.4169 81.7906 12.457V16.4394Z" fill="white"/>
<path d="M91.5165 22.2331V18.4607H91.4165C89.6453 18.4607 88.6647 17.7302 88.2145 17.29C87.6541 16.7496 87.0938 15.8191 87.0938 14.3982C87.0938 12.8572 87.7942 11.8766 88.4446 11.3563C89.2151 10.7459 90.3658 10.4157 91.4064 10.4157H91.5265V6.67334H94.0481V10.4157H94.1682C95.1988 10.4157 96.3595 10.7459 97.13 11.3563C97.7804 11.8766 98.4809 12.8572 98.4809 14.3982C98.4809 15.8191 97.9205 16.7396 97.3602 17.29C96.9099 17.7302 95.9293 18.4607 94.1582 18.4607H94.0581V22.2331H91.5165ZM91.6366 12.4469C91.0462 12.4169 90.4258 12.6171 90.0656 12.9873C89.7654 13.2875 89.5553 13.7878 89.5553 14.4082C89.5553 15.0186 89.7654 15.5289 90.0656 15.8391C90.3958 16.2093 90.9962 16.4494 91.6366 16.4294V12.4469ZM93.918 16.4394C94.5684 16.4595 95.1588 16.2093 95.489 15.8491C95.7892 15.5389 95.9993 15.0286 95.9993 14.4182C95.9993 13.8078 95.7892 13.2975 95.489 12.9973C95.1188 12.6271 94.5084 12.4169 93.918 12.457V16.4394Z" fill="white"/>
<path d="M105.586 10.5363H108.107V18.3412H105.586V17.4006C104.905 18.4512 103.925 18.6113 103.274 18.6113C102.154 18.6113 101.213 18.3312 100.353 17.4206C99.5321 16.55 99.2119 15.5294 99.2119 14.4988C99.2119 13.1879 99.7022 12.0672 100.423 11.3468C101.103 10.6664 102.054 10.2761 103.134 10.2761C103.795 10.2761 104.865 10.4362 105.586 11.4168V10.5363ZM102.384 13.0078C102.124 13.268 101.804 13.7283 101.804 14.4287C101.804 15.1292 102.104 15.5794 102.324 15.8296C102.674 16.1998 103.214 16.44 103.815 16.44C104.325 16.44 104.795 16.2298 105.126 15.8996C105.456 15.5894 105.736 15.0991 105.736 14.4287C105.736 13.8684 105.526 13.348 105.156 12.9978C104.775 12.6276 104.215 12.4575 103.755 12.4575C103.224 12.4475 102.714 12.6776 102.384 13.0078Z" fill="white"/>
<path d="M112.15 10.536V14.9988L115.472 10.536H117.813V18.3409H115.291V13.8081L111.969 18.3409H109.628V10.536H112.15ZM116.892 7.38404C116.682 8.01443 116.262 8.55477 115.772 8.87497C115.111 9.31525 114.301 9.36528 113.72 9.36528C113.14 9.36528 112.34 9.31525 111.669 8.87497C111.179 8.56478 110.759 8.01443 110.549 7.38404L112.3 6.70361C112.37 7.05383 112.54 7.33401 112.75 7.50411C112.98 7.69423 113.27 7.81431 113.71 7.81431C114.151 7.81431 114.441 7.69423 114.671 7.50411C114.881 7.33401 115.051 7.05383 115.121 6.70361L116.892 7.38404Z" fill="white"/>
<path d="M119.084 11.2067C119.414 10.9966 119.955 10.7164 120.375 10.5563C120.965 10.3462 121.616 10.2761 122.216 10.2761C122.636 10.2761 124.067 10.3061 124.858 11.0766C125.298 11.4969 125.438 11.9872 125.438 12.5075C125.438 12.9978 125.348 13.4381 124.968 13.8383C124.778 14.0285 124.548 14.1886 124.307 14.2786C124.888 14.4187 125.248 14.7289 125.358 14.839C125.738 15.2092 125.878 15.6295 125.878 16.1298C125.878 16.6701 125.708 17.2505 125.198 17.7608C124.538 18.4112 123.627 18.6013 122.326 18.6013C121.626 18.6013 120.895 18.5113 120.195 18.2511C119.514 17.9909 119.004 17.6007 118.794 17.4306L119.865 15.7696C120.145 16.0097 120.515 16.2599 120.825 16.3999C121.155 16.54 121.626 16.6401 122.036 16.6401C122.316 16.6401 122.696 16.5901 122.947 16.45C123.247 16.2799 123.297 16.0297 123.297 15.8596C123.297 15.5995 123.157 15.4393 122.927 15.3393C122.716 15.2492 122.296 15.1992 122.016 15.1992H121.075V13.4881H121.796C122.026 13.4881 122.496 13.4581 122.736 13.348C123.037 13.208 123.107 12.9778 123.107 12.8277C123.107 12.6876 123.037 12.4575 122.726 12.3174C122.606 12.2674 122.356 12.1773 121.936 12.1773C121.536 12.1773 121.235 12.2474 120.905 12.3874C120.575 12.5275 120.315 12.6876 120.065 12.8978L119.084 11.2067Z" fill="white"/>
<path d="M135.004 14.8783H129.181C129.181 15.2985 129.341 15.8589 129.651 16.2091C130.031 16.6294 130.562 16.6994 130.942 16.6994C131.312 16.6994 131.692 16.6294 131.952 16.4693C131.982 16.4492 132.283 16.2591 132.493 15.8189L134.874 16.059C134.524 17.1597 133.824 17.7401 133.373 18.0202C132.623 18.4705 131.782 18.6006 130.902 18.6006C129.731 18.6006 128.7 18.3905 127.79 17.4799C127.109 16.7995 126.619 15.7688 126.619 14.468C126.619 13.3473 127.019 12.1966 127.81 11.3861C128.75 10.4455 129.931 10.2654 130.802 10.2654C131.672 10.2654 132.933 10.4255 133.904 11.4361C134.834 12.4167 135.004 13.6375 135.004 14.6081V14.8783ZM132.683 13.3773C132.663 13.3073 132.543 12.817 132.173 12.4668C131.892 12.2066 131.492 12.0265 130.982 12.0265C130.331 12.0265 129.951 12.2866 129.701 12.5368C129.511 12.7469 129.331 13.0271 129.261 13.3773H132.683Z" fill="white"/>
<path d="M136.145 10.5366H138.667V13.2483H141.488V10.5366H144.01V18.3415H141.488V15.2796H138.667V18.3415H136.145V10.5366Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'IconStartupVillage',
};
</script>
