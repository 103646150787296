<!-- eslint-disable max-len -->
<template>
  <svg width="108" height="17" viewBox="0 0 108 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.4386 9.19348L11.0774 8.55811L11.1741 9.72076L3.53531 10.3561L3.4386 9.19348ZM0.98904 10.5679L3.53531 10.3561L3.92213 15.0067L1.37586 15.2185L0.98904 10.5679ZM11.1741 9.72076L13.7204 9.50897L13.8171 10.6716L11.2708 10.8834L11.1741 9.72076ZM11.4642 13.2087L14.0105 12.9969L14.1072 14.1596L11.5609 14.3714L11.4642 13.2087ZM3.92213 15.0067L11.5609 14.3714L11.6576 15.534L4.01883 16.1694L3.92213 15.0067Z"
      fill="currentColor"
    />
    <path
      d="M29.6879 14.0343L27.1416 14.2461L26.6581 8.43286L19.0193 9.06823L19.5028 14.8815L16.9565 15.0933L16.3763 8.11737L29.1076 7.05841L29.6879 14.0343Z"
      fill="currentColor"
    />
    <path
      d="M34.4066 6.61767L42.0454 5.9823L42.1421 7.14495L34.5033 7.78032L34.4066 6.61767ZM31.957 7.99211L34.5033 7.78032L34.7934 11.2683L42.4322 10.6329L42.1421 7.14495L44.6883 6.93316L45.1719 12.7464L42.6256 12.9582L42.5289 11.7956L34.8901 12.4309L34.9868 13.5936L32.4405 13.8054L31.957 7.99211Z"
      fill="currentColor"
    />
    <path
      d="M50.084 7.65506L47.5377 7.86685L47.441 6.7042L49.9872 6.49241L50.084 7.65506ZM60.269 6.8079L57.7228 7.01969L57.6261 5.85704L60.1723 5.64525L60.269 6.8079ZM57.8195 8.18234L55.2732 8.39413L55.1765 7.23148L57.7228 7.01969L57.8195 8.18234ZM55.3699 9.55679L52.8236 9.76858L52.7269 8.60592L55.2732 8.39413L55.3699 9.55679ZM52.9203 10.9312L50.3741 11.143L50.2774 9.98037L52.8236 9.76858L52.9203 10.9312ZM50.4708 12.3057L47.9245 12.5175L47.8278 11.3548L50.3741 11.143L50.4708 12.3057ZM60.6558 11.4585L58.1096 11.6703L58.0129 10.5076L60.5591 10.2959L60.6558 11.4585Z"
      fill="currentColor"
    />
    <path
      d="M63.2151 8.90425L65.7613 8.69246L65.6646 7.52981L63.1184 7.7416L63.0217 6.57895L65.5679 6.36716L65.4712 5.2045L68.0175 4.99271L68.1142 6.15536L70.6605 5.94357L70.5638 4.78092L73.11 4.56913L73.2067 5.73178L75.753 5.51999L75.8497 6.68265L73.3034 6.89444L73.4001 8.05709L75.9464 7.8453L76.0431 9.00795L73.4968 9.21974L73.5936 10.3824L71.0473 10.5942L70.9506 9.43153L68.4043 9.64332L68.501 10.806L65.9548 11.0178L65.858 9.85511L63.3118 10.0669L63.2151 8.90425ZM70.8539 8.26888L70.7572 7.10623L68.2109 7.31802L68.3076 8.48067L70.8539 8.26888Z"
      fill="currentColor"
    />
    <path
      d="M88.594 3.28122L80.9552 3.9166L80.8585 2.75394L88.4973 2.11857L88.594 3.28122ZM80.9552 3.9166L81.342 8.5672L78.7958 8.77899L78.4089 4.12839L80.9552 3.9166ZM91.1403 3.06943L91.4304 6.55739L83.7916 7.19276L83.5982 4.86746L86.1444 4.65567L86.2411 5.81832L88.7874 5.60653L88.594 3.28122L91.1403 3.06943ZM81.342 8.5672L91.5271 7.72004L91.6238 8.88269L81.4387 9.72985L81.342 8.5672Z"
      fill="currentColor"
    />
    <path
      d="M96.3425 1.46604L103.981 0.830664L104.078 1.99332L96.4392 2.62869L96.3425 1.46604ZM93.8929 2.84048L96.4392 2.62869L96.7293 6.11664L104.368 5.48127L104.078 1.99332L106.624 1.78153L107.108 7.59479L104.562 7.80658L104.465 6.64392L96.826 7.27929L96.9227 8.44195L94.3765 8.65374L93.8929 2.84048Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiRefBroken',
};
</script>
