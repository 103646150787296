<!-- eslint-disable max-len -->
<template>
  <svg width="88" height="72" viewBox="0 0 88 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.24006 67.3199H2.12006V71.9999H0.560059V62.6399H2.12006V65.7599H5.24006V67.3199ZM8.36006 64.1999H6.80006V62.6399H8.36006V64.1999ZM6.80006 65.7599H5.24006V64.1999H6.80006V65.7599ZM5.24006 68.8799V67.3199H6.80006V68.8799H5.24006ZM6.80006 68.8799H8.36006V71.9999H6.80006V68.8799Z" fill="currentColor"/>
    <path d="M11.6065 62.6399H16.2865V64.1999H11.6065V62.6399ZM10.0465 64.1999H11.6065V70.4399H10.0465V64.1999ZM16.2865 64.1999H17.8465V70.4399H16.2865V64.1999ZM11.6065 70.4399H16.2865V71.9999H11.6065V70.4399Z" fill="currentColor"/>
    <path d="M19.5329 62.6399H21.0929V64.1999H22.653V65.7599H21.0929V71.9999H19.5329V62.6399ZM24.2129 64.1999H25.7729V62.6399H27.333V71.9999H25.7729V65.7599H24.2129V64.1999ZM22.653 65.7599H24.2129V67.3199H22.653V65.7599Z" fill="currentColor"/>
    <path d="M36.8194 71.9999H35.2594V64.1999H30.5794V71.9999H29.0194V62.6399H36.8194V71.9999Z" fill="currentColor"/>
    <path d="M44.7458 71.9999H38.5058V62.6399H40.0658V65.7599H44.7458V67.3199H40.0658V70.4399H44.7458V71.9999ZM46.3058 67.3199V70.4399H44.7458V67.3199H46.3058Z" fill="currentColor"/>
    <path d="M51.1123 70.4399V67.3199H49.5523V71.9999H47.9923V62.6399H49.5523V65.7599H51.1123V64.1999H52.6723V70.4399H51.1123ZM52.6723 62.6399H57.3523V64.1999H52.6723V62.6399ZM58.9123 64.1999V70.4399H57.3523V64.1999H58.9123ZM57.3523 71.9999H52.6723V70.4399H57.3523V71.9999Z" fill="currentColor"/>
    <path d="M60.6018 62.6399H68.4018V64.1999H65.2818V71.9999H63.7218V64.1999H60.6018V62.6399Z" fill="currentColor"/>
    <path d="M70.0882 62.6399H77.8882V64.1999H71.6482V65.7599H76.3282V67.3199H71.6482V70.4399H77.8882V71.9999H70.0882V62.6399Z" fill="currentColor"/>
    <path d="M79.5747 62.6399H85.8147V64.1999H81.1347V68.8799H85.8147V70.4399H81.1347V71.9999H79.5747V62.6399ZM85.8147 64.1999H87.3747V68.8799H85.8147V64.1999Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31 8H27V32H31H55H59V8H55H31ZM55 12V28H31V12H55ZM63 36H23V40H63V36Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiPc',
};
</script>
