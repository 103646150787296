<!-- eslint-disable max-len -->
<template>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1667 6H10.8334V8.66667H24.1666V11.3333H26.8333V8.66664H24.1667V6ZM24.1666 27.3334H10.8334V30H24.1667V27.3334H26.8333V24.6667H24.1666V27.3334ZM26.8334 11.3333H29.5V24.6666H26.8334V11.3333ZM10.8333 11.3333H8.16667V24.6666H5.5V11.3333H8.16664V8.66664H10.8333V11.3333ZM8.16664 24.6667H10.8333V27.3334H8.16664V24.6667Z" fill="currentColor"/>
    <rect x="16.5857" y="12.9714" width="1.82857" height="10.0571" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiOff',
};
</script>
