<!-- eslint-disable max-len -->
<template>
  <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.10261 64.32H1.98261V69H0.422607V59.64H1.98261V62.76H5.10261V64.32ZM8.22261 61.2H6.66261V59.64H8.22261V61.2ZM6.66261 62.76H5.10261V61.2H6.66261V62.76ZM5.10261 65.88V64.32H6.66261V65.88H5.10261ZM6.66261 65.88H8.22261V69H6.66261V65.88Z" fill="currentColor"/>
    <path d="M11.4691 59.64H16.1491V61.2H11.4691V59.64ZM9.90905 61.2H11.4691V67.44H9.90905V61.2ZM16.1491 61.2H17.7091V67.44H16.1491V61.2ZM11.4691 67.44H16.1491V69H11.4691V67.44Z" fill="currentColor"/>
    <path d="M19.3955 59.64H25.6355V61.2H20.9555V65.88H25.6355V67.44H20.9555V69H19.3955V59.64ZM25.6355 61.2H27.1955V65.88H25.6355V61.2Z" fill="currentColor"/>
    <path d="M35.1219 61.2H30.4419V59.64H35.1219V61.2ZM28.8819 61.2H30.4419V62.76H28.8819V61.2ZM36.6819 62.76H35.1219V61.2H36.6819V62.76ZM35.1219 64.32H32.0019V62.76H35.1219V64.32ZM35.1219 64.32H36.6819V67.44H35.1219V64.32ZM30.4419 65.88V67.44H28.8819V65.88H30.4419ZM35.1219 69H30.4419V67.44H35.1219V69Z" fill="currentColor"/>
    <path d="M41.4884 65.88V67.44H39.9284V69H38.3684V59.64H39.9284V65.88H41.4884ZM46.1684 59.64V69H44.6084V64.32H43.0484V62.76H44.6084V59.64H46.1684ZM41.4884 64.32H43.0484V65.88H41.4884V64.32Z" fill="currentColor"/>
    <path d="M47.8548 59.64H49.4148V62.76H54.0948V59.64H55.6548V69H54.0948V64.32H49.4148V69H47.8548V59.64Z" fill="currentColor"/>
    <path d="M58.9013 59.64H63.5813V61.2H58.9013V59.64ZM57.3413 61.2H58.9013V65.88H63.5813V61.2H65.1413V69H63.5813V67.44H58.9013V69H57.3413V61.2Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9993 4H40.0002V8H39.9993V12H43.9993H47.9993H51.9993V16H47.9993V40H48.0002V44L47.9993 44H43.9993H20.0002L16.0002 44V40V16H11.9993V12H16.0002H20.0002H24.0002V8V4H28.0002H35.9993H39.9993ZM35.9993 8H28.0002V12H35.9993V8ZM35.9993 16H28.0002H24.0002L20.0002 16V40H43.9993V16L39.9993 16H35.9993ZM25.9993 20H29.9993V36H25.9993V20ZM38.0002 20H34.0002V36H38.0002V20Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiTrash',
};
</script>
