<!-- eslint-disable max-len -->
<template>
  <svg width="92" height="16" viewBox="0 0 92 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0H10.63V2.65751H2.65751V10.63H10.63V13.2875H2.65751V15.9451H0V0ZM10.63 2.65751H13.2875V10.63H10.63V2.65751ZM10.63 13.2875H13.2875V15.9451H10.63V13.2875Z"
      fill="currentColor"
    />
    <path
      d="M18.818 0H26.7905V2.65751H18.818V0ZM16.1605 2.65751H18.818V10.63H26.7905V2.65751H29.448V15.9451H26.7905V13.2875H18.818V15.9451H16.1605V2.65751Z"
      fill="currentColor"
    />
    <path d="M32.3209 0H34.9784V15.9451H32.3209V0Z" fill="currentColor" />
    <path
      d="M37.8626 0H51.1501V2.65751H40.5201V5.31502H48.4926V7.97253H40.5201V15.9451H37.8626V0Z"
      fill="currentColor"
    />
    <path
      d="M64.8823 0H72.8549V2.65751H64.8823V0ZM62.2248 2.65751H64.8823V13.2875H62.2248V2.65751ZM72.8549 2.65751H75.5124V13.2875H72.8549V2.65751ZM64.8823 13.2875H72.8549V15.9451H64.8823V13.2875Z"
      fill="currentColor"
    />
    <path
      d="M81.0428 0H89.0153V2.65751H81.0428V0ZM78.3853 2.65751H81.0428V5.31502H78.3853V2.65751ZM81.0428 5.31502H89.0153V7.97253H81.0428V5.31502ZM89.0153 7.97253H91.6729V13.2875H89.0153V7.97253ZM78.3853 10.63H81.0428V13.2875H78.3853V10.63ZM81.0428 13.2875H89.0153V15.9451H81.0428V13.2875Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiLogo',
};
</script>
