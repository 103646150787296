<template>
  <div class="conversation">
    <Card
      v-for="(k, i) in reversedKeys"
      :key="k"
      ref="dialogCards"
      :style="getCardStyle(i)"
      style="opacity: 0"
      no-border
      :is-blue="isLastMessage"
    >
      <Dialog
        :withNpc="current !== 'last'"
        has-border
        class="conversation__dialog"
        :class="`${current === 'last' ? 'blue bordered' : 'bordered'}`"
      >
        <div class="conversation-content" v-if="currentMsg">
          <div class="conversation-content__subtitle" v-if="currentMsg.subtitle">
            {{ currentMsg.subtitle }}
          </div>
          <div class="conversation-content__title" v-if="currentMsg.title">
            {{ currentMsg.title }}
          </div>
          <div class="conversation-content__text" v-if="currentMsg.text">
            {{ currentMsg.text }}
          </div>
          <img
            v-if="currentMsg.image"
            :src="`/images/${currentMsg.image}`"
            class="conversation-content__image"
          />
        </div>
      </Dialog>
    </Card>
    <div class="flex-1" />
    <div
      v-if="currentMsg && currentMsg.answers.length"
      class="conversation-buttons"
      ref="dialogButtons"
    >
      <Button
        is-full-width
        v-for="(answer, idx) in currentMsg.answers"
        :key="`'answer-${idx}'`"
        @click="next(answer.next, answer.type, answer.value)"
        :is-primary="isLastMessage"
        :is-mono="!isLastMessage && currentMsg.answers.length === 1"
        :is-has-bg="!isLastMessage"
        :is-gray="!isLastMessage"
      >
        {{ answer.text }}
      </Button>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

import Button from '@/components/UI/Button.vue';
import Dialog from './Dialog.vue';
import Card from './Card.vue';

export default {
  name: 'Conversation',
  components: { Dialog, Button, Card },
  data() {
    return {
      current: 0,
    };
  },
  props: {
    content: { type: Object, default: () => {} },
    endOfDialog: { type: Function },
  },
  computed: {
    currentMsg() {
      return this.content && this.content[this.current];
    },
    fullLength() {
      return Object.keys(this.content).length;
    },
    isLastMessage() {
      return ['last', 'prevLast'].includes(this.current);
    },
    reversedKeys() {
      return Object.keys(this.content).reverse();
    },
  },
  mounted() {
    this.cardAppear();
  },
  methods: {
    getCardStyle() {
      return {
        position: 'absolute',
        top: '0px',
      };
    },
    showButtons() {
      gsap.to(this.$refs.dialogButtons, {
        duration: 0.35,
        autoAlpha: 1,
      });
    },
    cardAppear(duration = 0) {
      setTimeout(() => {
        const currentCard = this.$refs.dialogCards.find(
          (r) => r.$vnode.key === this.current.toString(),
        );
        currentCard.appear(() => {
          currentCard.flip();
          this.showButtons();
        }, 0.5);
      }, duration);
    },
    next(id, type, value) {
      if (type && type === 'userPatch') {
        this.$emit('userPatch', value);
      }
      if (id === 'end') {
        this.endOfDialog();
      } else {
        gsap.to(this.$refs.dialogButtons, {
          duration: 0.35,
          autoAlpha: 0,
        });
        const currentCard = this.$refs.dialogCards.find(
          (r) => r.$vnode.key === this.current.toString(),
        );
        currentCard.disappear(() => {
          this.current = id;
          this.$nextTick(() => {
            this.cardAppear();
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.conversation {
  @apply relative z-0 flex flex-col justify-between pb-16;
  height: 100%;

  &__dialog {
    padding-bottom: 32px;
    padding-top: 32px;
  }

  &-content {
    @apply text-center mx-auto;
    &__subtitle {
      @apply text-14 leading-18 mb-12 font-navigo-regular;
      color: #5187ff;
    }
    &__title {
      @apply text-16 leading-26 mb-16 uppercase font-navigo-medium;
    }
    &__text {
      @apply text-16 leading-24 font-navigo-thin;
    }
    &__image {
      @apply mt-36 mx-auto;
      max-width: 80%;
    }
  }

  &-buttons {
    opacity: 0;

    .martian-button--gray {
      @apply font-navigo-thin py-12;
      height: auto;
      line-height: 16px;
      &:last-child {
        @apply mt-12;
      }
    }
  }
}
</style>
