<template>
  <div class="code-letter-outer" :class="{ 'code-letter-outer--hidden': !$slots.default }">
    <div class="code-letter">
      <slot v-if="!!$slots.default" />
      <img v-else :src="$options.key" />
    </div>
  </div>
</template>

<script>
import key from '@/assets/images/key.svg';

export default {
  key,
};
</script>

<style lang="scss" scoped>
.code-letter-outer {
  @apply h-36 w-36 p-4;
  border: 0.56px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  &--hidden {
    opacity: 0.5;
  }
}

.code-letter {
  @apply text-18 leading-22 font-navigo-medium w-full h-full uppercase box-border text-center;
  background: rgba(254, 230, 0, 0.05);
  border: 0.56px solid rgba(254, 230, 0, 0.5);
  padding-top: 3px;
  -webkit-text-stroke: 0.8px #fee600;
  -webkit-text-fill-color: transparent;
  & img {
    margin: 3px auto 0 auto;
  }
}
</style>
