<!-- eslint-disable max-len -->
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#icon-failure)">
      <path
        d="M35.875 12.4043L11.875 36.4043"
        stroke="#CC1F44"
        stroke-width="4.02516"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.875 12.4043L35.875 36.4043"
        stroke="#CC1F44"
        stroke-width="4.02516"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="icon-failure">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconFailure',
  components: {},
  data() {
    return {};
  },
};
</script>
