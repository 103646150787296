<template>
  <div class="result">
    <template>
      <!-- <div class="result__title">Ты победил и занял</div>
      <div class="result__position" v-if="gameInfo">{{ gameInfo.rank }} место</div> -->
      <div class="result__good-result">Отличный результат!</div>
      <!-- <p>{{ resultTextCurrent }}</p> -->
      <p class="mt-16 mb-16">В 20:00 по мск мы объявим победителей!</p>
      <p class="mb-32">
        5 лучших участников получат набор фирменного мерча от&nbsp;Райффайзен&nbsp;Банк:
        <br/>
        толстовка, носки, поясная сумка, шапка, перчатки, ручка, карандаш, блокнот.
      </p>
      <social-network-links size="sm" />
      <div class="result__social-text">Мы в соцсетях</div>
      <Button
        class="absolute bottom-16"
        style="width: calc(100% - 20px)"
        is-primary
        is-full-width
        is-uppercase
        @click="handleNext"
        >Вернуться на главную</Button
      >
    </template>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue';
import resultText from '@/assets/text/gameResultText.json';
import SocialNetworkLinks from './SocialNetworkLinks.vue';

export default {
  name: 'GameResult',
  components: { SocialNetworkLinks, Button },
  data() {
    return {
      phone: '',
      resultText,
    };
  },
  props: {
    next: {
      type: Function,
      // required: true,
    },
  },
  methods: {
    handleSendPhone() {
      try {
        this.$http.patch(`/users/${this.user.id}`, { phone: this.phone }).then(() => {
          this.$emit('click');
        });
      } catch (e) {
        console.log('[send-phone] error', e);
      }
    },
    handleNext() {
      // $emit('click')
      this.$router.push({ name: 'LandingPage' });
    },
  },
  mounted() {},
  computed: {
    resultTextCurrent() {
      if (this.successAnswersRatio < 0.2) {
        return this.resultText.poor;
      }
      if (this.successAnswersRatio < 0.4) {
        return this.resultText.fair;
      }
      if (this.successAnswersRatio < 0.8) {
        return this.resultText.good;
      }
      return this.resultText.excellent;
    },
    successAnswersRatio() {
      return this.$store.getters['game/getSuccessRatio'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    hasPhone() {
      return this.user?.phone;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    phoneComplete() {
      return this.phone && this.phone.trim().length === 18;
    },
  },
};
</script>

<style lang="scss" scoped>
.result {
  @apply text-center pb-72 px-12 pt-8 flex flex-col justify-center relative
    bg-no-repeat text-white;
  background-size: contain;
  background-position: top, bottom, top;
  background-image: linear-gradient(
      0deg,
      rgba(16, 16, 16, 0) 0%,
      rgba(16, 16, 16, 0.76) 50%,
      #101010 100%
    ),
    linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.76) 50%, #101010 100%);
  height: 100%;
  &__title {
    @apply font-navigo-medium text-16 leading-28 uppercase;
  }
  &__position {
    @apply font-navigo-medium text-primary text-32 leading-38 mt-4 uppercase;
  }
  &__good-result {
    @apply font-navigo-medium text-primary text-24 leading-32 mt-4 uppercase;
  }
  & p {
    @apply text-16 leading-24 font-navigo-thin;
  }
  &__next-btn {
    @apply uppercase absolute bottom-16;
    width: calc(100% - 24px);
  }
  & .social-container {
    margin: 0 auto;
  }
  &__social-text {
    @apply text-gray-100 text-12 leading-18 mt-12 font-navigo-regular;
  }
  & .martian-button {
    @apply absolute bottom-12;
    width: calc(100% - 24px);
  }
}
.geecko-input {
  @apply text-18 leading-28 font-navigo-medium px-16 focus:border-orange focus:outline-none;
  background: #101010;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 52px;
  border-radius: 4px;
}
.geecko-label {
  @apply text-12 leading-18 font-navigo-regular mb-8 text-left;
}
</style>
