<!-- eslint-disable max-len -->
<template>
  <svg width="48" height="21" viewBox="0 0 48 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.59948 9.97472L6.51616 12.2527L4.31633 11.9076L4.233 14.1855L3.13309 14.013L3.21642 11.735L1.01659 11.3898L1.09991 9.11188L3.29974 9.45702L3.54972 2.62311L1.34989 2.27797L1.43321 0L6.93278 0.862848L6.84946 3.14082L4.64963 2.79568L4.39965 9.62959L6.59948 9.97472ZM0 8.93931L0.249975 2.1054L1.34989 2.27797L1.09991 9.11188L0 8.93931ZM7.94937 3.31339L7.6994 10.1473L6.59948 9.97472L6.84946 3.14082L7.94937 3.31339Z"
      fill="currentColor"
    />
    <path
      d="M13.5402 4.19056L10.2405 3.67285L10.3238 1.39488L13.6236 1.91259L13.5402 4.19056ZM10.2405 3.67285L9.9072 12.7847L8.80728 12.6122L9.14058 3.50028L10.2405 3.67285ZM14.6402 4.36313L14.3902 11.197L11.0904 10.6793L11.2571 6.12339L12.357 6.29596L12.2737 8.57393L13.3736 8.7465L13.5402 4.19056L14.6402 4.36313ZM9.9072 12.7847L14.3069 13.475L14.2235 15.753L9.82387 15.0627L9.9072 12.7847Z"
      fill="currentColor"
    />
    <path
      d="M18.7957 14.1793L19.8956 14.3518L19.8122 16.6298L16.5125 16.1121L16.5958 13.8341L17.6957 14.0067L17.7791 11.7287L16.6792 11.5562L16.7625 9.27819L17.8624 9.45076L17.9457 7.17279L16.8458 7.00022L16.9291 4.72226L18.029 4.89482L18.1124 2.61686L19.2123 2.78943L19.129 5.06739L20.2289 5.23996L20.1455 7.51793L19.0456 7.34536L18.9623 9.62333L20.0622 9.7959L19.9789 12.0739L18.879 11.9013L18.7957 14.1793ZM16.8458 7.00022L16.7625 9.27819L15.6626 9.10562L15.7459 6.82765L16.8458 7.00022ZM21.0788 12.2464L20.9955 14.5244L19.8956 14.3518L19.9789 12.0739L21.0788 12.2464Z"
      fill="currentColor"
    />
    <path
      d="M26.9175 6.28937L23.6178 5.77166L23.7011 3.49369L27.0008 4.0114L26.9175 6.28937ZM23.6178 5.77166L23.2845 14.8835L22.1846 14.711L22.5179 5.59909L23.6178 5.77166ZM28.0174 6.46194L27.7674 13.2958L24.4677 12.7781L24.6344 8.2222L25.7343 8.39477L25.6509 10.6727L26.7509 10.8453L26.9175 6.28937L28.0174 6.46194ZM23.2845 14.8835L27.6841 15.5738L27.6008 17.8518L23.2011 17.1615L23.2845 14.8835Z"
      fill="currentColor"
    />
    <path
      d="M33.6061 7.33877L30.3064 6.82106L30.3897 4.5431L33.6895 5.0608L33.6061 7.33877ZM30.3064 6.82106L29.9731 15.9329L28.8732 15.7604L29.2065 6.6485L30.3064 6.82106ZM34.7061 7.51134L34.4561 14.3453L31.1563 13.8275L31.323 9.2716L32.4229 9.44417L32.3396 11.7221L33.4395 11.8947L33.6061 7.33877L34.7061 7.51134ZM29.9731 15.9329L34.3728 16.6232L34.2894 18.9012L29.8898 18.2109L29.9731 15.9329Z"
      fill="currentColor"
    />
    <path
      d="M37.2457 1.01758L40.5454 1.53529L40.4614 3.83224L37.1617 3.31453L37.2457 1.01758ZM37.845 14.8769L37.7616 17.1549L36.6617 16.9823L36.5784 19.2603L35.4785 19.0877L35.9784 5.41993L37.0784 5.5925L36.7451 14.7044L37.845 14.8769ZM41.478 6.28278L40.9781 19.9506L39.8782 19.778L40.1281 12.9441L39.0282 12.7715L39.1115 10.4936L40.2114 10.6661L40.3781 6.11021L41.478 6.28278ZM37.9283 12.599L39.0282 12.7715L38.9449 15.0495L37.845 14.8769L37.9283 12.599Z"
      fill="currentColor"
    />
    <path
      d="M45.9668 6.98705L45.8835 9.26501L44.7836 9.09245L44.8669 6.81448L45.9668 6.98705ZM43.6837 8.91988L44.7836 9.09245L44.7003 11.3704L43.6003 11.1978L43.6837 8.91988ZM46.9834 9.43758L46.9001 11.7156L45.8002 11.543L45.8835 9.26501L46.9834 9.43758ZM43.6003 11.1978L43.267 20.3097L42.1671 20.1372L42.5004 11.0253L43.6003 11.1978ZM48 11.8881L47.6667 21L46.5668 20.8274L46.9001 11.7156L48 11.8881Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiFileBroken',
};
</script>
