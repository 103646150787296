<style lang="scss">
.game {
  @apply bg-black;
  width: 100%;
  background-image: url('/images/game-screen-bg.png');
  background-size: 1280px;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.game-page-container {
  @apply flex justify-center items-center relative;
  height: 100vh;
  background: #000000;
  background-image: url('/images/1280_star.png');
  background-size: contain;
  overflow: hidden;
}

.game-inner-container {
  @apply relative overflow-hidden w-full h-full z-50;
  background: #131313;
  border-radius: 4px;
}

.settings-button {
  @apply absolute rounded-full cursor-pointer z-50;
  --size: 32px;
  height: var(--size);
  width: var(--size);
  top: 13px;
  left: calc(50% - var(--size) / 2);
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  & svg {
    @apply mx-auto;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>

<template>
  <div ref="gameOuter" id="gameOuter" class="game">
    <window-ui isBroken>
      <div class="game-inner-container" id="game" ref="game">
        <button class="settings-button" @click="openSettingsModal"><icon-settings /></button>
        <game />
      </div>
    </window-ui>
  </div>
</template>

<script>
import Game from '../components/Game.vue';
import IconSettings from '../components/Icons/IconSettings.vue';
import WindowUi from '../components/LandingPage/WindowUi.vue';
import SettingsModal from '../components/SettingsModal.vue';

export default {
  components: {
    Game,
    WindowUi,
    IconSettings,
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    // eslint-disable-next-line func-names
    'user.id': function (val) {
      if (!val) {
        this.$router.push({ name: 'LandingPage' });
      }
    },
  },
  methods: {
    openSettingsModal() {
      this.$modal.show(
        SettingsModal,
        {},
        {
          name: 'settings-modal',
          classes: 'settings-modal',
          height: 'auto',
          width: document.documentElement.clientWidth > 768 ? '400px' : '100%',
          clickToClose: true,
          scrollable: true,
        },
        {
          'before-open': () => {},
          'before-close': () => {},
        },
      );
    },
  },
};
</script>
