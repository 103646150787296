<!-- eslint-disable max-len -->
<template>
  <svg width="127" height="21" viewBox="0 0 127 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.809082 0L15.393 1.11685L15.2581 2.87809L4.32018 2.04046L3.91555 7.32419L14.8535 8.16183L14.7186 9.92307L3.78067 9.08543L3.64579 10.8467L-0.000184618 10.5675L0.809082 0ZM15.2581 2.87809L18.9041 3.15731L18.4994 8.44104L14.8535 8.16183L15.2581 2.87809ZM14.7186 9.92307L18.3646 10.2023L18.2297 11.9635L14.5837 11.6843L14.7186 9.92307Z"
      fill="currentColor"
    />
    <path
      d="M26.6264 1.97712L37.5643 2.81475L37.4295 4.576L26.4915 3.73836L26.6264 1.97712ZM22.8456 3.45915L26.4915 3.73836L26.0869 9.02209L37.0248 9.85973L37.4295 4.576L41.0754 4.85521L40.4011 13.6614L36.7551 13.3822L36.89 11.621L25.952 10.7833L25.8172 12.5446L22.1712 12.2654L22.8456 3.45915Z"
      fill="currentColor"
    />
    <path
      d="M45.1518 3.39581L48.7978 3.67502L47.9885 14.2425L44.3426 13.9633L45.1518 3.39581Z"
      fill="currentColor"
    />
    <path
      d="M52.7547 3.97805L70.9846 5.37411L70.8497 7.13535L56.2658 6.0185L56.1309 7.77975L67.0689 8.61738L66.934 10.3786L55.9961 9.54099L55.5914 14.8247L51.9455 14.5455L52.7547 3.97805Z"
      fill="currentColor"
    />
    <path
      d="M89.8245 6.81689L100.762 7.65452L100.628 9.41577L89.6896 8.57813L89.8245 6.81689ZM86.0436 8.29892L89.6896 8.57813L89.1501 15.6231L85.5041 15.3439L86.0436 8.29892ZM100.628 9.41577L104.274 9.69498L103.734 16.74L100.088 16.4607L100.628 9.41577ZM89.1501 15.6231L100.088 16.4607L99.9531 18.222L89.0152 17.3844L89.1501 15.6231Z"
      fill="currentColor"
    />
    <path
      d="M111.996 8.51479L122.934 9.35243L122.799 11.1137L111.861 10.276L111.996 8.51479ZM108.215 9.99682L111.861 10.276L111.726 12.0373L108.08 11.7581L108.215 9.99682ZM111.726 12.0373L122.664 12.8749L122.529 14.6362L111.591 13.7985L111.726 12.0373ZM122.529 14.6362L126.175 14.9154L125.905 18.4379L122.259 18.1586L122.529 14.6362ZM107.81 15.2806L111.456 15.5598L111.321 17.321L107.676 17.0418L107.81 15.2806ZM111.321 17.321L122.259 18.1586L122.125 19.9199L111.187 19.0823L111.321 17.321Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiLogoBroken',
};
</script>
