<!-- eslint-disable max-len -->
<template>
  <svg width="73" height="12" viewBox="0 0 73 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.68 10.08H8.6V12H2.84V10.08H4.76V8.16H2.84V6.24H4.76V4.32H2.84V2.4H4.76V0.48H6.68V2.4H8.6V4.32H6.68V6.24H8.6V8.16H6.68V10.08ZM2.84 4.32V6.24H0.92V4.32H2.84ZM10.52 8.16V10.08H8.6V8.16H10.52ZM20.2756 2.4H14.5156V0.48H20.2756V2.4ZM14.5156 2.4V10.08H12.5956V2.4H14.5156ZM22.1956 2.4V8.16H16.4356V4.32H18.3556V6.24H20.2756V2.4H22.1956ZM14.5156 10.08H22.1956V12H14.5156V10.08ZM26.1912 4.32H24.2712V2.4H26.1912V4.32ZM33.8713 4.32H31.9513V2.4H33.8713V4.32ZM31.9513 6.24H30.0312V4.32H31.9513V6.24ZM30.0312 8.16H28.1112V6.24H30.0312V8.16ZM28.1112 10.08H26.1912V8.16H28.1112V10.08ZM26.1912 12H24.2712V10.08H26.1912V12ZM33.8713 12H31.9513V10.08H33.8713V12ZM35.9469 0.48H37.8669V8.16H35.9469V0.48ZM35.9469 10.08H37.8669V12H35.9469V10.08ZM39.9506 8.16H41.8706V6.24H39.9506V4.32H41.8706V2.4H43.7906V4.32H45.7106V2.4H47.6306V4.32H49.5506V6.24H47.6306V8.16H49.5506V10.08H47.6306V12H45.7106V10.08H43.7906V12H41.8706V10.08H39.9506V8.16ZM45.7106 8.16V6.24H43.7906V8.16H45.7106ZM53.5462 4.32H51.6262V2.4H53.5462V4.32ZM61.2262 4.32H59.3062V2.4H61.2262V4.32ZM59.3062 6.24H57.3862V4.32H59.3062V6.24ZM57.3862 8.16H55.4662V6.24H57.3862V8.16ZM55.4662 10.08H53.5462V8.16H55.4662V10.08ZM53.5462 12H51.6262V10.08H53.5462V12ZM61.2262 12H59.3062V10.08H61.2262V12ZM69.0619 3.36H67.1419V1.44H69.0619V3.36ZM67.1419 5.28H65.2219V3.36H67.1419V5.28ZM70.9819 5.28H69.0619V3.36H70.9819V5.28ZM65.2219 7.2H63.3019V5.28H65.2219V7.2ZM72.9019 7.2H70.9819V5.28H72.9019V7.2Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerLogoTitleBroken',
};
</script>
