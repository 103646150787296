<template>
  <div class="game-layout">
    <template v-if="gameInfo && gameInfo.id && quizComplete && showFinal">
      <hud-panel :answeredQuestions="answeredQuestions"></hud-panel>
      <outro v-if="gameInfo.is_finished" />
      <template v-else>
        <code-enter />
      </template>
    </template>
    <template v-else>
      <hud-panel v-if="gameInfo && gameInfo.id" :answeredQuestions="answeredQuestions"></hud-panel>
      <quiz v-if="gameInfo && gameInfo.id && (!quizComplete || (quizComplete && !showFinal))" />
      <conversation
        v-else
        :content="dialogContent"
        :endOfDialog="endOfDialog"
        @userPatch="handleSetMobilePlatform"
      />
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import HudPanel from '@/components/HudPanel.vue';
import Conversation from './Conversation.vue';
import CodeEnter from './CodeEnter.vue';
import Quiz from './Quiz.vue';
import Outro from './Outro.vue';

import dialogContent from '@/assets/text/gameIntroText.json';

export default {
  name: 'Game',
  components: {
    HudPanel,
    Conversation,
    CodeEnter,
    Quiz,
    Outro,
  },
  data() {
    return {
      interval: 30,
      dialogContent: dialogContent,
    };
  },
  methods: {
    endOfDialog() {
      this.$store.dispatch('game/startGame').then(async () => {
        await this.$store.dispatch('game/fetchGame');
        await this.$store.dispatch('game/fetchQuestion');
      });
    },
    handleSetMobilePlatform(value) {
      this.$store.dispatch('user/updateUser', {'mobile_platform': value})
    }
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    showFinal() {
      return this.$store.state.game.showFinal;
    },
    quizComplete() {
      return this.gameInfo?.stages.every(
        (item) => item.answered_questions === item.total_questions,
      );
    },
    answeredQuestions() {
      return this.gameInfo
        ? this.gameInfo.stages.reduce((acc, curr) => acc + curr.answered_questions, 0)
        : 0;
    },
  },
  watch: {},
  async mounted() {
    if (this.quizComplete) {
      this.$store.dispatch('game/setCurrentStageNumber', 4);
      this.$store.commit('game/SET_SHOW_FINAL', true);
    }
    // разкомментить для рестарта
    // await this.$store.dispatch('game/restartGame');
  },
};
</script>

<style lang="scss">
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.game-layout {
  @apply bg-top relative bg-no-repeat;
  height: 100%;
  padding-top: 120px;
  padding-left: 10px;
  padding-right: 10px;
  background-size: contain;
  background-image: url('/images/game-blur-ellipse.svg');

  &--no-bg {
    background: none;
    padding-top: 0;
  }
}
</style>
