import axios from 'axios';

const defaultState = {
  gameInfo: null,
  question: {},
  currentStageNumber: 1,
  isCounterActive: false,
  showFinal: false,
};

const mutations = {
  SET_IS_COUNTER_ACTIVE: (state, payload) => {
    state.isCounterActive = payload;
  },
  SET_CURRENT_STAGE_NUMBER: (state, payload) => {
    state.currentStageNumber = payload;
  },
  SET_GAME_INFO: (state, payload) => {
    state.gameInfo = payload;
  },
  SET_SHOW_FINAL: (state, payload) => {
    state.showFinal = payload;
  },
  SET_QUESTION: (state, payload) => {
    state.question = payload;
  },
  GAME_INFO_RESET: (state) => {
    state.gameInfo = null;
  },
};

const actions = {
  activateCounter({ commit }) {
    commit('SET_IS_COUNTER_ACTIVE', true);
  },
  pauseCounter({ commit }) {
    commit('SET_IS_COUNTER_ACTIVE', false);
  },
  setCurrentStageNumber({ commit }, data) {
    commit('SET_CURRENT_STAGE_NUMBER', data);
  },
  upStage({ commit, state }) {
    commit('SET_CURRENT_STAGE_NUMBER', state.currentStageNumber + 1);
  },
  clearQuestion({ commit }) {
    commit('SET_QUESTION', {});
  },
  async fetchQuestion({ commit, getters /* , dispatch */ }) {
    try {
      const { id } = getters.currentStage;
      if (getters.currentStage) {
        const { data } = await axios.get(`/game/get-text-question/${id}`);
        commit('SET_QUESTION', data);
        console.log('[vuex-game] fetchQuestion done', data);
      }
    } catch (e) {
      console.log('[vuex-user] fetchQuestion error', e);
    }
  },
  async fetchGame({ commit/* , dispatch */ }) {
    console.log('[vuex-game] fetchGame start');
    try {
      const { data } = await axios.get('/game');
      console.log('[vuex-user] get response from /game', data);
      if (data) {
        const gameInfo = { ...data, stages: data.stages.sort((a, b) => a.stage - b.stage) };
        commit('SET_GAME_INFO', gameInfo);
      }
      console.log('[vuex-game] fetchGame done', data);
    } catch (e) {
      console.log('[vuex-user] fetchGame error', e);
    }
  },
  async startGame() {
    console.log('[vuex-game] startGame start');
    await axios.post('/game/new-game');
    console.log('[vuex-game] startGame done');
  },
  async restartGame({ commit, dispatch }) {
    console.log('[vuex-game] restartGame start');
    commit('SET_CURRENT_STAGE_NUMBER', 1);
    commit('SET_IS_COUNTER_ACTIVE', false);
    commit('SET_SHOW_FINAL');
    localStorage.removeItem('counter');
    await dispatch('finishGame');
    commit('GAME_INFO_RESET');
    console.log('[vuex-game] restartGame done');
  },
  async finishGame() {
    console.log('[vuex-game] finishGame done');
    // await axios.post('/game/finish')
    await axios.post('/game/new-game');
    console.log('[vuex-game] finishGame done');
  },
};

const getters = {
  code: (state) => (state.gameInfo.stages ? state.gameInfo.stages.map((s) => s.code_part) : []),
  currentStage: (state) => state.gameInfo.stages.find((s) => s.stage === state.currentStageNumber),
  timeBeforeGame: () => {
    const gameStartAt = new Date('2020-04-13T10:35');
    const now = new Date();
    const timeDiffSeconds = (gameStartAt - now) / 1000;
    return timeDiffSeconds;
  },
  getSuccessRatio: (state) => {
    const commonResult = state.gameInfo.stages.reduce(
      (res, curr) => {
        // eslint-disable-next-line no-param-reassign
        res.correct += curr.correct_answers;
        // eslint-disable-next-line no-param-reassign
        res.all += curr.answered_questions;
        return res;
      },
      { correct: 0, all: 0 },
    );
    return commonResult.correct / commonResult.all;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
