<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33329 3.3335H4.66663V4.66683H3.33329V3.3335ZM4.66659 4.66691H5.99992V6.00024H4.66659V4.66691ZM7.33325 7.33358H5.99992L5.99992 6.00024L7.33325 6.00024V7.33358ZM8.66659 7.33358H7.33325V8.66691H5.99992V10.0002H4.66659L4.66659 11.3336H3.33325V12.6669H4.66659V11.3336H5.99992L5.99992 10.0002H7.33325V8.66691H8.66659V10.0002H9.99992V11.3336H11.3333V12.6669H12.6666V11.3336H11.3333L11.3333 10.0002H9.99992V8.66691H8.66659V7.33358ZM9.99992 6.00024L9.99992 7.33358H8.66659V6.00024L9.99992 6.00024ZM11.3333 4.66691V6.00024H9.99992V4.66691H11.3333ZM11.3333 4.66691V3.33358H12.6666V4.66691H11.3333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMailerClose',
};
</script>
