<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="3.05672" fill="#2B2D33"/>
    <g clip-path="url(#geecko-square)">
    <path d="M9.54505 5.52948V3.97546H8.15734C8.11464 3.97546 8.05059 3.97546 8.00789 3.97546C6.27858 3.97546 4.89087 5.35917 4.89087 7.08349C4.89087 8.78651 6.27858 10.1915 8.00789 10.1915C8.56298 10.1915 9.09671 10.0425 9.5664 9.78704V10.9792H11.1249V5.52948H9.54505ZM9.09671 8.16917C8.81917 8.44591 8.43488 8.61621 8.00789 8.61621C7.15391 8.61621 6.44938 7.91371 6.44938 7.0622C6.44938 6.63644 6.62018 6.25326 6.89772 5.97652C7.17526 5.69978 7.55955 5.52948 7.98654 5.52948C8.02924 5.52948 8.05059 5.52948 8.09329 5.52948C8.47758 5.55076 8.84052 5.72107 9.09671 5.97652C9.37426 6.25326 9.54505 6.63644 9.54505 7.0622C9.54505 7.50924 9.37426 7.89242 9.09671 8.16917Z" fill="white"/>
    <path d="M9.52368 10.9579H6.02237V12.5119H9.52368V10.9579Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="geecko-square">
    <rect width="6.2408" height="8.54925" fill="white" transform="translate(4.88753 3.96423)"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconGeeckoSquare',
};
</script>
