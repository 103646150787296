<!-- eslint-disable max-len -->
<template>
  <svg width="67" height="86" viewBox="0 0 67 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.37666 60.2H2.13666V68H0.57666V58.64H8.37666V60.2Z" fill="currentColor"/>
      <path d="M11.6231 58.64H16.3031V60.2H11.6231V58.64ZM10.0631 60.2H11.6231V66.44H10.0631V60.2ZM16.3031 60.2H17.8631V66.44H16.3031V60.2ZM11.6231 66.44H16.3031V68H11.6231V66.44Z" fill="currentColor"/>
      <path d="M28.9096 66.44V68H19.5496V66.44H21.1096V60.2H22.6696V66.44H25.7896V60.2H22.6696V58.64H27.3496V66.44H28.9096Z" fill="currentColor"/>
      <path d="M32.1575 58.64H36.8375V60.2H32.1575V58.64ZM30.5975 60.2H32.1575V66.44H30.5975V60.2ZM36.8375 60.2H38.3975V66.44H36.8375V60.2ZM32.1575 66.44H36.8375V68H32.1575V66.44Z" fill="currentColor"/>
      <path d="M40.084 58.64H46.324V60.2H41.644V61.76H46.324V63.32H41.644V66.44H46.324V68H40.084V58.64ZM46.324 60.2H47.884V61.76H46.324V60.2ZM46.324 63.32H47.884V66.44H46.324V63.32Z" fill="currentColor"/>
      <path d="M51.1304 58.64H55.8104V60.2H51.1304V58.64ZM49.5704 60.2H51.1304V66.44H49.5704V60.2ZM55.8104 60.2H57.3704V66.44H55.8104V60.2ZM51.1304 66.44H55.8104V68H51.1304V66.44Z" fill="currentColor"/>
      <path d="M60.6169 55.507H65.2969V57.08H60.6169V55.507ZM62.1769 64.88V66.44H60.6169V68H59.0569V58.64H60.6169V64.88H62.1769ZM66.8569 58.64V68H65.2969V63.32H63.7369V61.76H65.2969V58.64H66.8569ZM62.1769 63.32H63.7369V64.88H62.1769V63.32Z" fill="currentColor"/>
      <path d="M12.4689 76.64H17.1489V78.2H12.4689V76.64ZM10.9089 78.2H12.4689V84.44H10.9089V78.2ZM17.1489 78.2H18.7089V84.44H17.1489V78.2ZM12.4689 84.44H17.1489V86H12.4689V84.44Z" fill="currentColor"/>
      <path d="M20.3953 76.64H28.1953V78.2H25.0753V86H23.5153V78.2H20.3953V76.64Z" fill="currentColor"/>
      <path d="M29.8818 82.88V76.64H31.4418V82.88H29.8818ZM37.6818 76.64V86H36.1218V84.44H31.4418V82.88H36.1218V76.64H37.6818Z" fill="currentColor"/>
      <path d="M39.3682 76.64H47.1682V78.2H40.9282V79.76H45.6082V81.32H40.9282V84.44H47.1682V86H39.3682V76.64Z" fill="currentColor"/>
      <path d="M48.8547 76.64H56.6547V78.2H53.5347V86H51.9747V78.2H48.8547V76.64Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39 4H43V8H47H51V16V20V44L47 44H19L15 44V20V16V8H19H23V4H27V8H39V4ZM19 16H47V12H19V16ZM19 20V40H47V20H19Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWindowUiReport',
};
</script>
