<style lang='scss'>
.settings-modal {
  @apply px-4 sm:px-16 py-12 sm:py-16 relative;
  border-radius: 4px;
  color: #ffffff;
  & .g-game-settings {
    @apply bg-transparent;
  }
  &.vm--modal {
    background-color: #232425;
  }
}
.close-icon {
  @apply absolute;
  top: 8px;
  right: 8px;
  opacity: 0.5;
  border: 1px solid #3d3c3d;
  box-sizing: border-box;
  color: #58595b;
  border-radius: 4px;
  --size: 24px;
  width: var(--size);
  height: var(--size);
  & svg {
    @apply mx-auto;
  }
  &:hover {
    opacity: 0.8;
  }
}
</style>

<template>
  <div>
    <button class="close-icon" @click="$emit('close')">
      <icon-cross />
    </button>
    <g-activity-settings v-bind="settingsModalProps" />
  </div>
</template>

<script>
import IconCross from './Icons/IconCross.vue';

export default {
  name: 'SettingsModal',
  components: {
    gActivitySettings: () => import('@geeckocom/g-activity-settings'),
    IconCross,
  },
  data() {
    return {
      docs: [
        { name: 'Пользовательское соглашение', link: '/docs/agreement.pdf' },
        {
          name: 'Согласие на обработку ПД',
          link: '/docs/privacy_policy.pdf',
        },
      ],
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$emit('close');
      });
    },
  },
  computed: {
    settingsModalProps() {
      // TODO: не обновился .env.prod на сервере, вместо VUE_APP_GEECKO_ID_URL ссылка
      const profileLink = new URL('profile', 'https://id.geecko.com');
      return {
        user: this.user,
        logout: this.logout,
        useDocs: true,
        docs: this.docs,
        theme: 'dark',
        profileLink: profileLink.href,
      };
    },
    user() {
      return this.$store.state.user.user;
    },
  },
};
</script>
