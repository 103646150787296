<!-- eslint-disable max-len -->
<template>
  <svg width="31" height="12" viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path d="M2.83992 0.47998H8.59992V2.39998H2.83992V0.47998ZM0.919922 2.39998H2.83992V4.31998H0.919922V2.39998ZM8.59992 2.39998H10.5199V4.31998H8.59992V2.39998ZM4.75992 4.31998H8.59992V6.23998H4.75992V4.31998ZM8.59992 6.23998H10.5199V10.08H8.59992V6.23998ZM0.919922 8.15998H2.83992V10.08H0.919922V8.15998ZM2.83992 10.08H8.59992V12H2.83992V10.08Z" fill="white"/>
      <path d="M14.5155 0.47998H20.2755V2.39998H14.5155V0.47998ZM12.5955 2.39998H14.5155V4.31998H12.5955V2.39998ZM20.2755 2.39998H22.1955V6.23998H20.2755V2.39998ZM20.2755 6.23998V8.15998H14.5155V6.23998H20.2755ZM12.5955 8.15998H14.5155V10.08H22.1955V12H12.5955V8.15998Z" fill="white"/>
      <path d="M24.2712 10.08H26.1912V4.31998H24.2712V2.39998H26.1912V0.47998H28.1112V10.08H30.0312V12H24.2712V10.08Z" fill="white"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMailerCount',
};
</script>
